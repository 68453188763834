import apiInstance from "./axiosBase";

export const VersionAPI = {
  uploadFile: (formData) => {
    return apiInstance.post("/project/version/upload", formData);
  },
  listVersions: (projectId) => {
    return apiInstance.get(`/project/version/list/${projectId}`);
  },
  getVersion: (id) => {
    return apiInstance.get(`/project/version/${id}`);
  },
  getVersionData: (formData) => {
    return apiInstance.post(`/project/version/data`, formData);
  },
  setPrevious: (formData) => {
    return apiInstance.post(`/project/version/previous`, formData);
  },
  deleteVersion: (projectId, versionId) => {
    return apiInstance.delete(
      `/project/version/delete/${projectId}/${versionId}`
    );
  },
  download: (projectId, fileName) => {
    return apiInstance.get(
      `/project/version/download/${projectId}/${fileName}`,
      {
        responseType: "blob",
      }
    );
  },
};
