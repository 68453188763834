import apiInstance from "./axiosBase";

export const ProjectPeopleAPI = {
  list: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post("/project/projectpeople/list/", formData);
  },
  getPerson: (id) => {
    return apiInstance.get(`/project/projectpeople/${id}`);
  },
  addPerson: (formData) => {
    return apiInstance.post("/project/projectpeople/add", formData);
  },
  updatePerson: (formData) => {
    return apiInstance.post("/project/projectpeople/update", formData);
  },
  deletePerson: (id) => {
    return apiInstance.delete(`/project/projectpeople/delete/${id}`);
  },
};
