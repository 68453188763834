import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectViewGroupViewRoomsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  projectViewGroupViewRooms: [],
  selectedProjectViewGroupViewRoom: null,
  // appSate: - https://twitter.com/DavidKPiano/status/972620672743673856?s=20&t=04l-JgTcX-o9OqrpzjMFDg
};

export const projectViewGroupViewRoomsSlice = createSlice({
  name: "projectViewGroupViewRooms",
  initialState,
  reducers: {
    createProjectViewGroupViewRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createProjectViewGroupViewRoomSuccess: (state, { payload }) => {
      const returnedProjectView =
        returnStreamlinedProjectViewGroupViewRoomData(payload);
      const projectViewGroupViewRoomsCopy = state.projectViewGroupViewRooms;
      const newProjectView = updateObjectArray(
        projectViewGroupViewRoomsCopy,
        returnedProjectView
      );

      state.projectViewGroupViewRooms = newProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    createProjectViewGroupViewRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchProjectViewGroupViewRooms: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchProjectViewGroupViewRoomsSuccess: (state, { payload }) => {
      const processedProjectView = payload.map((pg) =>
        returnStreamlinedProjectViewGroupViewRoomData(pg)
      );
      state.projectViewGroupViewRooms = processedProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchProjectViewGroupViewRoomsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeProjectViewGroupViewRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeProjectViewGroupViewRoomSuccess: (state, { payload }) => {
      const convertedResponse =
        returnStreamlinedProjectViewGroupViewRoomData(payload);
      const projectViewGroupViewRoomsCopy = state.projectViewGroupViewRooms;
      const filteredProjectView = projectViewGroupViewRoomsCopy.filter(
        (pg) =>
          pg.projectViewGroupViewRoomId !==
          convertedResponse.projectViewGroupViewRoomId
      );
      state.projectViewGroupViewRooms = filteredProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    removeProjectViewGroupViewRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    emptyProjectViewGroupViewRooms: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    emptyProjectViewGroupViewRoomsSuccess: (state) => {
      state.projectViewGroupViewRooms = [];
      status.stateStatus(status.SUCCESS, state);
    },
    emptyProjectViewGroupViewRoomsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectProjectViewGroupViewRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectProjectViewGroupViewRoomSuccess: (state, { payload }) => {
      state.selectedProjectViewGroupViewRoom = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectProjectViewGroupViewRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectProjectViewGroupViewRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectProjectViewGroupViewRoomSuccess: (state) => {
      state.selectedProjectViewGroupViewRoom = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectProjectViewGroupViewRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createProjectViewGroupViewRoom,
  createProjectViewGroupViewRoomSuccess,
  createProjectViewGroupViewRoomFailed,
  fetchProjectViewGroupViewRooms,
  fetchProjectViewGroupViewRoomsSuccess,
  fetchProjectViewGroupViewRoomsFailed,
  removeProjectViewGroupViewRoom,
  removeProjectViewGroupViewRoomSuccess,
  removeProjectViewGroupViewRoomFailed,
  emptyProjectViewGroupViewRooms,
  emptyProjectViewGroupViewRoomsSuccess,
  emptyProjectViewGroupViewRoomsFailed,
  selectProjectViewGroupViewRoom,
  selectProjectViewGroupViewRoomSuccess,
  selectProjectViewGroupViewRoomFailed,
  deselectProjectViewGroupViewRoom,
  deselectProjectViewGroupViewRoomSuccess,
  deselectProjectViewGroupViewRoomFailed,
} = projectViewGroupViewRoomsSlice.actions;

// reducer
export default projectViewGroupViewRoomsSlice.reducer;

// async thunks
export function getProjectViewGroupViewRooms(projectViewGroupViewId) {
  return async (dispatch) => {
    dispatch(fetchProjectViewGroupViewRooms());
    try {
      const fd = new FormData();
      fd.append("projectViewGroupViewId", projectViewGroupViewId);
      const res =
        await ProjectViewGroupViewRoomsAPI.listProjectViewGroupViewRooms(fd);
      dispatch(fetchProjectViewGroupViewRoomsSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchProjectViewGroupViewRoomsFailed());
    }
  };
}

export function addProjectViewGroupViewRoom(
  projectId,
  projectViewGroupViewId,
  roomId
) {
  return async (dispatch) => {
    dispatch(createProjectViewGroupViewRoom());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("projectViewGroupViewId", projectViewGroupViewId);
      fd.append("roomId", roomId);
      const res =
        await ProjectViewGroupViewRoomsAPI.addProjectViewGroupViewRoom(fd);
      dispatch(createProjectViewGroupViewRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createProjectViewGroupViewRoomFailed());
    }
  };
}

export function deleteProjectViewGroupViewRoom(projectViewGroupViewRoomId) {
  return async (dispatch) => {
    dispatch(removeProjectViewGroupViewRoom());
    try {
      const res =
        await ProjectViewGroupViewRoomsAPI.deleteProjectViewGroupViewRoom(
          projectViewGroupViewRoomId
        );
      dispatch(removeProjectViewGroupViewRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeProjectViewGroupViewRoomFailed());
    }
  };
}

export function setSelectedProjectViewGroupViewRoom(projectViewGroupView) {
  return async (dispatch) => {
    dispatch(selectProjectViewGroupViewRoom());
    try {
      dispatch(selectProjectViewGroupViewRoomSuccess(projectViewGroupView));
    } catch (error) {
      console.log(error);
      dispatch(selectProjectViewGroupViewRoomFailed());
    }
  };
}

export function resetSelectedProjectViewGroupViewRoom() {
  return async (dispatch) => {
    dispatch(deselectProjectViewGroupViewRoom());
    try {
      dispatch(deselectProjectViewGroupViewRoomSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectProjectViewGroupViewRoomFailed());
    }
  };
}

export function clearProjectViewGroupViewRooms() {
  return async (dispatch) => {
    dispatch(emptyProjectViewGroupViewRooms());
    try {
      dispatch(emptyProjectViewGroupViewRoomsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(emptyProjectViewGroupViewRoomsFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedProjectViewGroupViewRoomData = (
  projectViewGroupViewRoomData
) => {
  return {
    projectViewGroupViewRoomId: projectViewGroupViewRoomData._id,
    projectViewGroupViewRoomRoomId: projectViewGroupViewRoomData.room._id,
    projectViewGroupViewRoomName: projectViewGroupViewRoomData.room.name,
    projectViewGroupViewRoomDescription:
      projectViewGroupViewRoomData.room.description,
  };
};
