import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { GridViewsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  gridViews: [],
  selectedGridView: null,
};

export const gridViewSlice = createSlice({
  name: "gridViews",
  initialState,
  reducers: {
    createGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createGridViewSuccess: (state, { payload }) => {
      const returnedView = returnStreamlinedGridViewData(payload);
      const gridViewsCopy = state.gridViews;
      const newViews = updateObjectArray(gridViewsCopy, returnedView);

      state.gridViews = newViews;
      status.stateStatus(status.SUCCESS, state);
    },
    createGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeGridViewSuccess: (state, { payload }) => {
      const updatedView = returnStreamlinedGridViewData(payload);
      const gridViewsCopy = [...state.gridViews];

      // find view position
      const vIndex = gridViewsCopy.findIndex(
        (v) => v.gridViewId === updatedView.gridViewId
      );
      // update view in position
      gridViewsCopy[vIndex] = {
        ...gridViewsCopy[vIndex],
        ...updatedView,
      };
      state.gridViews = [...gridViewsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchGridViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchGridViewsSuccess: (state, { payload }) => {
      const processedViews = payload.map((vg) =>
        returnStreamlinedGridViewData(vg)
      );
      state.gridViews = processedViews;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchGridViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeGridViewSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedGridViewData(payload);
      const gridViewsCopy = state.gridViews;
      const filteredViews = gridViewsCopy.filter(
        (vg) => vg.gridViewId !== convertedResponse.gridViewId
      );
      state.gridViews = filteredViews;
      status.stateStatus(status.SUCCESS, state);
    },
    removeGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectGridViewSuccess: (state, { payload }) => {
      state.selectedGridView = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetGridViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetGridViewsSuccess: (state) => {
      state.gridViews = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetGridViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedGridViewSuccess: (state) => {
      state.selectedGridView = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createGridView,
  createGridViewSuccess,
  createGridViewFailed,
  changeGridView,
  changeGridViewSuccess,
  changeGridViewFailed,
  fetchGridViews,
  fetchGridViewsSuccess,
  fetchGridViewsFailed,
  removeGridView,
  removeGridViewSuccess,
  removeGridViewFailed,
  selectGridView,
  selectGridViewSuccess,
  selectGridViewFailed,
  resetGridViews,
  resetGridViewsSuccess,
  resetGridViewsFailed,
  resetSelectedGridView,
  resetSelectedGridViewSuccess,
  resetSelectedGridViewFailed,
} = gridViewSlice.actions;

// reducer
export default gridViewSlice.reducer;

// async thunks

// grid views
export function getGridViews() {
  return async (dispatch) => {
    dispatch(fetchGridViews());
    try {
      const response = await GridViewsAPI.listGridViews();
      dispatch(fetchGridViewsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchGridViewsFailed());
    }
  };
}

export function addGridView(formData) {
  return async (dispatch) => {
    dispatch(createGridView());
    try {
      const res = await GridViewsAPI.addGridView(formData);
      dispatch(createGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createGridViewFailed());
    }
  };
}

export function updateGridView(formData) {
  return async (dispatch) => {
    dispatch(changeGridView());
    try {
      const res = await GridViewsAPI.updateGridView(formData);
      dispatch(changeGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeGridViewFailed());
    }
  };
}

export function duplicateGridView(formData) {
  return async (dispatch) => {
    dispatch(createGridView());
    try {
      const res = await GridViewsAPI.duplicateGridView(formData);
      dispatch(createGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createGridViewFailed());
    }
  };
}

export function deleteGridView(gridViewId) {
  return async (dispatch) => {
    dispatch(removeGridView());
    try {
      const res = await GridViewsAPI.deleteGridView(gridViewId);
      dispatch(removeGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeGridViewFailed());
    }
  };
}

export function setSelectedGridView(gridView) {
  return async (dispatch) => {
    dispatch(selectGridView());
    try {
      dispatch(selectGridViewSuccess(gridView));
    } catch (error) {
      console.log(error);
      dispatch(selectGridViewFailed());
    }
  };
}

export function clearGridViews() {
  return async (dispatch) => {
    dispatch(resetGridViews());
    try {
      dispatch(resetGridViewsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetGridViewsFailed());
    }
  };
}

export function clearSelectedGridView() {
  return async (dispatch) => {
    dispatch(resetSelectedGridView());
    try {
      dispatch(resetSelectedGridViewSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedGridViewFailed());
    }
  };
}
// only get the bits of the payload data needed
const returnStreamlinedGridViewData = (gridViewData) => {
  return {
    gridViewId: gridViewData._id,
    gridViewName: gridViewData.name,
    gridViewDescription: gridViewData.description,
    gridViewHeadBgColour: gridViewData.headBgColour,
    gridViewHeadTextColour: gridViewData.headTextColour,
    gridViewSubHeadBgColour: gridViewData.subHeadBgColour,
    gridViewSubHeadTextColour: gridViewData.subHeadTextColour,
    gridViewDate: gridViewData.updatedAt,
  };
};
