import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { GridViewRowsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  gridViewRows: [],
  selectedGridViewRow: null,
};

export const gridViewRowsSlice = createSlice({
  name: "gridViewRows",
  initialState,
  reducers: {
    createGridViewRow: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createGridViewRowSuccess: (state, { payload }) => {
      const returnedView = returnStreamlinedGridViewData(payload);
      const gridViewRowsCopy = state.gridViewRows;
      const newViews = updateObjectArray(gridViewRowsCopy, returnedView);

      state.gridViewRows = newViews;
      status.stateStatus(status.SUCCESS, state);
    },
    createGridViewRowFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeGridViewRow: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeGridViewRowSuccess: (state, { payload }) => {
      const updatedRow = returnStreamlinedGridViewData(payload);
      const gridViewRowsCopy = [...state.gridViewRows];

      // find view position
      const vIndex = gridViewRowsCopy.findIndex(
        (v) => v.gridViewRowId === updatedRow.gridViewRowId
      );
      // update view in position
      gridViewRowsCopy[vIndex] = {
        ...gridViewRowsCopy[vIndex],
        ...updatedRow,
      };
      state.gridViewRows = [...gridViewRowsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeGridViewRowFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchGridViewRows: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchGridViewRowsSuccess: (state, { payload }) => {
      const processedViews = payload.map((vg) =>
        returnStreamlinedGridViewData(vg)
      );
      state.gridViewRows = processedViews;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchGridViewRowsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeGridViewRow: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeGridViewRowSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedGridViewData(payload);
      const gridViewRowsCopy = state.gridViewRows;
      const filteredViews = gridViewRowsCopy.filter(
        (vg) => vg.gridViewRowId !== convertedResponse.gridViewRowId
      );
      state.gridViewRows = filteredViews;
      status.stateStatus(status.SUCCESS, state);
    },
    removeGridViewRowFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectGridViewRow: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectGridViewRowSuccess: (state, { payload }) => {
      state.selectedGridView = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectGridViewRowFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetGridViewRows: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetGridViewRowsSuccess: (state) => {
      state.gridViewRows = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetGridViewRowsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedGridViewRow: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedGridViewRowSuccess: (state) => {
      state.selectedGridView = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedGridViewRowFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createGridViewRow,
  createGridViewRowSuccess,
  createGridViewRowFailed,
  changeGridViewRow,
  changeGridViewRowSuccess,
  changeGridViewRowFailed,
  fetchGridViewRows,
  fetchGridViewRowsSuccess,
  fetchGridViewRowsFailed,
  removeGridViewRow,
  removeGridViewRowSuccess,
  removeGridViewRowFailed,
  selectGridViewRow,
  selectGridViewRowSuccess,
  selectGridViewRowFailed,
  resetGridViewRows,
  resetGridViewRowsSuccess,
  resetGridViewRowsFailed,
  resetSelectedGridViewRow,
  resetSelectedGridViewRowSuccess,
  resetSelectedGridViewRowFailed,
} = gridViewRowsSlice.actions;

// reducer
export default gridViewRowsSlice.reducer;

// async thunks

// grid views
export function getGridViewRows(gridViewId) {
  return async (dispatch) => {
    dispatch(fetchGridViewRows());
    try {
      const response = await GridViewRowsAPI.listGridViewRows(gridViewId);
      dispatch(fetchGridViewRowsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchGridViewRowsFailed());
    }
  };
}

export function addGridViewRow(formData) {
  return async (dispatch) => {
    dispatch(createGridViewRow());
    try {
      const res = await GridViewRowsAPI.addGridViewRow(formData);
      dispatch(createGridViewRowSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createGridViewRowFailed());
    }
  };
}

export function updateGridViewRow(formData) {
  return async (dispatch) => {
    dispatch(changeGridViewRow());
    try {
      const res = await GridViewRowsAPI.updateGridViewRow(formData);
      dispatch(changeGridViewRowSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeGridViewRowFailed());
    }
  };
}

export function deleteGridViewRow(gridViewRowId) {
  return async (dispatch) => {
    dispatch(removeGridViewRow());
    try {
      const res = await GridViewRowsAPI.deleteGridViewRow(gridViewRowId);
      dispatch(removeGridViewRowSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeGridViewRowFailed());
    }
  };
}

export function setSelectedGridViewRow(gridView) {
  return async (dispatch) => {
    dispatch(selectGridViewRow());
    try {
      dispatch(selectGridViewRowSuccess(gridView));
    } catch (error) {
      console.log(error);
      dispatch(selectGridViewRowFailed());
    }
  };
}

export function clearGridViewRows() {
  return async (dispatch) => {
    dispatch(resetGridViewRows());
    try {
      dispatch(resetGridViewRowsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetGridViewRowsFailed());
    }
  };
}

export function clearSelectedGridViewRow() {
  return async (dispatch) => {
    dispatch(resetSelectedGridViewRow());
    try {
      dispatch(resetSelectedGridViewRowSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedGridViewRowFailed());
    }
  };
}
// only get the bits of the payload data needed
const returnStreamlinedGridViewData = (gridViewRowData) => {
  return {
    gridViewRowId: gridViewRowData._id,
    gridViewRowOrder: gridViewRowData.order,
    gridViewId: gridViewRowData.gridView._id,
    gridViewRowDate: gridViewRowData.updatedAt,
  };
};
