import { createSlice } from "@reduxjs/toolkit";
import { GridViewProjectPeopleAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  gridViewProjectPeople: [],
};

export const gridViewProjectPeopleSlice = createSlice({
  name: "gridViewProjectPeople",
  initialState,
  reducers: {
    changeGridViewProjectPeople: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeGridViewProjectPeopleSuccess: (state, { payload }) => {
      const updatedProjectPeople = returnStreamlinedGridViewData(payload);
      const gridViewProjectPeopleCopy = [...state.gridViewProjectPeople];
      const updated = [...gridViewProjectPeopleCopy, updatedProjectPeople];
      state.gridViewProjectPeople = updated;
      status.stateStatus(status.SUCCESS, state);
    },
    changeGridViewProjectPeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchGridViewProjectPeople: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchGridViewProjectPeopleSuccess: (state, { payload }) => {
      const processedRows = payload.map((vg) =>
        returnStreamlinedGridViewData(vg)
      );
      state.gridViewProjectPeople = processedRows;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchGridViewProjectPeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeGridViewProjectPerson: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeGridViewProjectPersonSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedGridViewData(payload);
      const gridViewProjectPeopleCopy = [...state.gridViewProjectPeople];
      const filteredFields = gridViewProjectPeopleCopy.filter(
        (f) =>
          f.gridViewProjectPeopleId !==
          convertedResponse.gridViewProjectPeopleId
      );

      state.gridViewProjectPeople = [...filteredFields];
      status.stateStatus(status.SUCCESS, state);
    },
    removeGridViewProjectPersonFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetGridViewProjectPeople: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetGridViewProjectPeopleSuccess: (state) => {
      state.gridViewProjectPeople = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetGridViewProjectPeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  changeGridViewProjectPeople,
  changeGridViewProjectPeopleSuccess,
  changeGridViewProjectPeopleFailed,
  fetchGridViewProjectPeople,
  fetchGridViewProjectPeopleSuccess,
  fetchGridViewProjectPeopleFailed,
  removeGridViewProjectPerson,
  removeGridViewProjectPersonSuccess,
  removeGridViewProjectPersonFailed,
  resetGridViewProjectPeople,
  resetGridViewProjectPeopleSuccess,
  resetGridViewProjectPeopleFailed,
} = gridViewProjectPeopleSlice.actions;

// reducer
export default gridViewProjectPeopleSlice.reducer;

// async thunks

// grid views
export function getGridViewProjectPeople(gridViewId, projectId, versionId) {
  return async (dispatch) => {
    dispatch(fetchGridViewProjectPeople());
    try {
      const fd = new FormData();
      fd.append("gridViewId", gridViewId);
      fd.append("projectId", projectId);
      fd.append("versionId", versionId);
      const response = await GridViewProjectPeopleAPI.listPeople(fd);
      dispatch(fetchGridViewProjectPeopleSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchGridViewProjectPeopleFailed());
    }
  };
}

export function addGridViewProjectPerson(formData) {
  return async (dispatch) => {
    dispatch(changeGridViewProjectPeople());
    try {
      const res = await GridViewProjectPeopleAPI.addPerson(formData);
      dispatch(changeGridViewProjectPeopleSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeGridViewProjectPeopleFailed());
    }
  };
}

export function deleteGridViewProjectPerson(gridViewProjectPeopleId) {
  return async (dispatch) => {
    dispatch(removeGridViewProjectPerson());
    try {
      const res = await GridViewProjectPeopleAPI.deletePerson(
        gridViewProjectPeopleId
      );
      dispatch(removeGridViewProjectPersonSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeGridViewProjectPersonFailed());
    }
  };
}

export function clearGridViewProjectPeople() {
  return async (dispatch) => {
    dispatch(resetGridViewProjectPeople());
    try {
      dispatch(resetGridViewProjectPeopleSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetGridViewProjectPeopleFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedGridViewData = (gridViewProjectPeopleData) => {
  return {
    gridViewProjectPeopleId: gridViewProjectPeopleData._id,
    gridViewProjectPeopleProjectId: gridViewProjectPeopleData.project._id,
    gridViewProjectPeopleGridViewId: gridViewProjectPeopleData.gridView._id,
    gridViewProjectPeopleVersionId: gridViewProjectPeopleData.version._id,
    gridViewProjectPeoplePersonId: gridViewProjectPeopleData.person._id,
    gridViewProjectPeoplePersonName: gridViewProjectPeopleData.person.name,
    gridViewProjectPeoplePersonRole: gridViewProjectPeopleData.person.role,
    gridViewProjectPeoplePersonTel: gridViewProjectPeopleData.person.tel,
    gridViewProjectPeoplePersonEmail: gridViewProjectPeopleData.person.email,
    gridViewProjectPeopleDate: gridViewProjectPeopleData.updatedAt,
  };
};
