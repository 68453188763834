import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectGridViewsAPI } from "../../API";
import * as status from "./stateStatus";

// TODO: appSate: - https://twitter.com/DavidKPiano/status/972620672743673856?s=20&t=04l-JgTcX-o9OqrpzjMFDg

export const initialState = {
  loading: false,
  hasErrors: false,
  projectGridViews: [],
  selectedProjectGridView: null,
};

export const projectGridViewSlice = createSlice({
  name: "projectGridViews",
  initialState,
  reducers: {
    createProjectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createProjectGridViewSuccess: (state, { payload }) => {
      const returnedProjectGridView =
        returnStreamlinedProjectGridViewData(payload);
      const projectGridViewsCopy = state.projectGridViews;
      const newProjectGridView = updateObjectArray(
        projectGridViewsCopy,
        returnedProjectGridView
      );

      state.projectGridViews = newProjectGridView;
      status.stateStatus(status.SUCCESS, state);
    },
    createProjectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchProjectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchProjectGridViewSuccess: (state, { payload }) => {
      const processedProjectGridView = payload.map((pg) =>
        returnStreamlinedProjectGridViewData(pg)
      );
      state.projectGridViews = processedProjectGridView;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchProjectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeProjectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeProjectGridViewSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedProjectGridViewData(payload);
      const projectGridViewsCopy = state.projectGridViews;
      const filteredProjectGridView = projectGridViewsCopy.filter(
        (pg) => pg.projectGridViewId !== convertedResponse.projectGridViewId
      );
      state.projectGridViews = filteredProjectGridView;
      status.stateStatus(status.SUCCESS, state);
    },
    removeProjectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    emptyProjectGridViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    emptyProjectGridViewsSuccess: (state) => {
      state.projectGridViews = [];
      status.stateStatus(status.SUCCESS, state);
    },
    emptyProjectGridViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectProjectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectProjectGridViewSuccess: (state, { payload }) => {
      state.selectedProjectGridView = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectProjectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectProjectGridView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectProjectGridViewSuccess: (state) => {
      state.selectedProjectGridView = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectProjectGridViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createProjectGridView,
  createProjectGridViewSuccess,
  createProjectGridViewFailed,
  fetchProjectGridView,
  fetchProjectGridViewSuccess,
  fetchProjectGridViewFailed,
  removeProjectGridView,
  removeProjectGridViewSuccess,
  removeProjectGridViewFailed,
  emptyProjectGridViews,
  emptyProjectGridViewsSuccess,
  emptyProjectGridViewsFailed,
  selectProjectGridView,
  selectProjectGridViewSuccess,
  selectProjectGridViewFailed,
  deselectProjectGridView,
  deselectProjectGridViewSuccess,
  deselectProjectGridViewFailed,
} = projectGridViewSlice.actions;

// reducer
export default projectGridViewSlice.reducer;

// async thunks
export function getProjectGridViews(projectId) {
  return async (dispatch) => {
    dispatch(fetchProjectGridView());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      const res = await ProjectGridViewsAPI.listProjectGridViews(fd);
      dispatch(fetchProjectGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchProjectGridViewFailed());
    }
  };
}

export function addProjectGridView(projectId, gridViewId) {
  return async (dispatch) => {
    dispatch(createProjectGridView());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("gridViewId", gridViewId);
      const res = await ProjectGridViewsAPI.addProjectGridView(fd);
      dispatch(createProjectGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createProjectGridViewFailed());
    }
  };
}

export function deleteProjectGridView(projectGridViewId) {
  return async (dispatch) => {
    dispatch(removeProjectGridView());
    try {
      const res = await ProjectGridViewsAPI.deleteProjectGridView(
        projectGridViewId
      );
      dispatch(removeProjectGridViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeProjectGridViewFailed());
    }
  };
}

export function setSelectedProjectGridView(projectGridView) {
  return async (dispatch) => {
    dispatch(selectProjectGridView());
    try {
      dispatch(selectProjectGridViewSuccess(projectGridView));
    } catch (error) {
      console.log(error);
      dispatch(selectProjectGridViewFailed());
    }
  };
}

export function resetSelectedProjectGridView() {
  return async (dispatch) => {
    dispatch(deselectProjectGridView());
    try {
      dispatch(deselectProjectGridViewSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectProjectGridViewFailed());
    }
  };
}

export function clearProjectGridViews() {
  return async (dispatch) => {
    dispatch(emptyProjectGridViews());
    try {
      dispatch(emptyProjectGridViewsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(emptyProjectGridViewsFailed());
    }
  };
}

// only get the bits of the payload needed
const returnStreamlinedProjectGridViewData = (projectGridViewData) => {
  return {
    projectGridViewId: projectGridViewData._id,
    projectGridViewProjectId: projectGridViewData.project._id,
    projectGridViewGridViewId: projectGridViewData.gridView._id,
    projectGridViewGridViewName: projectGridViewData.gridView.name,
    projectGridViewGridViewDescription:
      projectGridViewData.gridView.description,
  };
};
