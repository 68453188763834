import { Navigate, useLocation } from "react-router-dom";
import { refreshUser } from "../../../App/config/firebase-config";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();

  const checkToRefresh = () => {
    const token = localStorage.getItem("token");
    const tokenTimeout = localStorage.getItem("tokenTimeout");

    if (!token) return false;
    if (!tokenTimeout) return false;
    return checkTokenTimeout();
  };

  const checkTokenTimeout = async () => {
    const timeoutToken = localStorage.getItem("tokenTimeout");
    const expirationDate = parseInt(timeoutToken);
    const currDate = new Date().getTime();
    const isExpired = expirationDate < currDate;
    if (isExpired) {
      // expired get new token
      try {
        await refreshUser();
        return true;
      } catch (error) {
        console.log(error);
        return false;
      }
    }
    return true;
  };

  if (!checkToRefresh()) {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;
