import axios from "axios";
import environment from "../../App/config/environment";

const axiosInstance = axios.create({
  baseURL: environment.env.appUrl,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default axiosInstance;
