import { createSlice } from "@reduxjs/toolkit";
import { mapDataToWeeks } from "../../../utils";
import { VersionAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  changed: true,
  versions: [],
  selectedVersion: null,
  currentData: null,
  currentDataWeeks: null,
  previousData: null,
  previousDataWeeks: null,
};

export const versionSlice = createSlice({
  name: "versions",
  initialState,
  reducers: {
    fetchVersionList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    fetchVersionListSuccess: (state, { payload }) => {
      // only get the bits of the version data needed
      const mappedVersions = payload.map((p) =>
        returnStreamlinedVersionData(p)
      );
      state.versions = sortVersionsByOrder([...mappedVersions]);
      state.changed = false;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchVersionListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeVersionList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    changeVersionListSuccess: (state, { payload }) => {
      const addedVersion = returnStreamlinedVersionData(payload);
      const versionsCopy = [...state.versions];
      const updatedVersionsList = [...versionsCopy, addedVersion];
      state.versions = sortVersionsByOrder([...updatedVersionsList]);
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    changeVersionListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeVersion: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    changeVersionSuccess: (state, { payload }) => {
      const updatedVersion = returnStreamlinedVersionData(payload);
      const versionsCopy = [...state.versions];
      // find index
      const pIndex = versionsCopy.findIndex(
        (v) => v.versionId === updatedVersion.versionId
      );
      // updated in place
      versionsCopy[pIndex] = {
        ...versionsCopy[pIndex],
        ...updatedVersion,
      };
      state.versions = sortVersionsByOrder([...versionsCopy]);
      state.selectedVersion = updatedVersion;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    changeVersionFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    removeVersionFromList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    removeVersionFromListSuccess: (state, { payload }) => {
      const versionsCopy = [...state.versions];
      // filter out deleted
      const newArr = versionsCopy.filter(
        (p) => p.versionId !== payload.versionId
      );
      state.versions = sortVersionsByOrder([...newArr]);
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    removeVersionFromListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeSelectedVersion: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeSelectedVersionSuccess: (state, { payload }) => {
      state.selectedVersion = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    changeSelectedVersionFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchCurrentVersionData: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    fetchCurrentVersionDataSuccess: (state, { payload }) => {
      const currentData = payload;
      const mappedData = mapDataToWeeks(currentData);
      state.currentData = currentData;
      state.currentDataWeeks = mappedData;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchCurrentVersionDataFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    fetchPreviousVersionData: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    fetchPreviousVersionDataSuccess: (state, { payload }) => {
      const previousData = payload;
      const mappedData = mapDataToWeeks(previousData);
      state.previousData = previousData;
      state.previousDataWeeks = mappedData;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchPreviousVersionDataFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    resetVersionList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    resetVersionListSuccess: (state) => {
      state.versions = [];
      state.selectedVersion = null;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    resetVersionListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    resetVersionData: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    resetVersionDataSuccess: (state) => {
      state.currentData = null;
      state.currentDataWeeks = null;
      state.previousData = null;
      state.previousDataWeeks = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetVersionDataFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedVersion: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedVersionSuccess: (state) => {
      state.selectedVersion = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedVersionFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    updateChangeState: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    updateChangeStateSuccess: (state, { payload }) => {
      state.changed = false;
      status.stateStatus(status.SUCCESS, state);
    },
    updateChangeStateFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  fetchVersionList,
  fetchVersionListSuccess,
  fetchVersionListFailed,
  changeVersionList,
  changeVersionListSuccess,
  changeVersionListFailed,
  changeVersion,
  changeVersionSuccess,
  changeVersionFailed,
  removeVersionFromList,
  removeVersionFromListSuccess,
  removeVersionFromListFailed,
  changeSelectedVersion,
  changeSelectedVersionSuccess,
  changeSelectedVersionFailed,
  fetchCurrentVersionData,
  fetchCurrentVersionDataSuccess,
  fetchCurrentVersionDataFailed,
  fetchPreviousVersionData,
  fetchPreviousVersionDataSuccess,
  fetchPreviousVersionDataFailed,
  resetVersionData,
  resetVersionDataSuccess,
  resetVersionDataFailed,
  resetVersionList,
  resetVersionListSuccess,
  resetVersionListFailed,
  resetSelectedVersion,
  resetSelectedVersionSuccess,
  resetSelectedVersionFailed,
  updateChangeState,
  updateChangeStateSuccess,
  updateChangeStateFailed,
} = versionSlice.actions;

// reducer
export default versionSlice.reducer;

// async thunks
export function getVersions(projectId) {
  return async (dispatch) => {
    dispatch(fetchVersionList());
    try {
      const response = await VersionAPI.listVersions(projectId);
      dispatch(fetchVersionListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchVersionListFailed());
    }
  };
}

// async thunks
export function getVersionById(versionId) {
  return async (dispatch) => {
    dispatch(changeSelectedVersion());
    try {
      const response = await VersionAPI.getVersion(versionId);
      dispatch(changeSelectedVersionSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeSelectedVersionFailed());
    }
  };
}

export function addVersion(formData) {
  return async (dispatch) => {
    dispatch(changeVersionList());
    try {
      const response = await VersionAPI.uploadFile(formData);
      dispatch(changeVersionListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeVersionListFailed());
    }
  };
}

export function setPreviousVersion(versionId, previousVersionId) {
  return async (dispatch) => {
    dispatch(changeVersion());
    try {
      const fd = new FormData();
      fd.append("versionId", versionId);
      fd.append("previousVersionId", previousVersionId);
      const res = await VersionAPI.setPrevious(fd);
      dispatch(changeVersionSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeVersionFailed());
    }
  };
}

export function getCurrentVersionData(
  projectId,
  versionName,
  startDate,
  endDate,
  dateField
) {
  return async (dispatch) => {
    dispatch(fetchCurrentVersionData());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("versionName", versionName);
      fd.append("startDate", startDate);
      fd.append("endDate", endDate);
      fd.append("dateField", dateField);
      const res = await VersionAPI.getVersionData(fd);
      dispatch(fetchCurrentVersionDataSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchCurrentVersionDataFailed());
    }
  };
}

export function getPreviousVersionData(
  projectId,
  versionName,
  startDate,
  endDate,
  dateField
) {
  return async (dispatch) => {
    dispatch(fetchPreviousVersionData());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("versionName", versionName);
      fd.append("startDate", startDate);
      fd.append("endDate", endDate);
      fd.append("dateField", dateField);
      const res = await VersionAPI.getVersionData(fd);
      dispatch(fetchPreviousVersionDataSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchPreviousVersionDataFailed());
    }
  };
}

export function deleteVersion(projectId, versionId) {
  return async (dispatch) => {
    dispatch(removeVersionFromList());
    try {
      await VersionAPI.deleteVersion(projectId, versionId);
      const response = await VersionAPI.listVersions(projectId);
      dispatch(fetchVersionListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(removeVersionFromListFailed());
    }
  };
}

export function updateSelectedVersion(version) {
  return async (dispatch) => {
    dispatch(changeSelectedVersion());
    try {
      dispatch(changeSelectedVersionSuccess(version));
    } catch (error) {
      console.log(error);
      dispatch(changeSelectedVersionFailed());
    }
  };
}

export function resetVersions() {
  return async (dispatch) => {
    dispatch(resetVersionList());
    try {
      dispatch(resetVersionListSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetVersionListFailed());
    }
  };
}

export function resetVersion() {
  return async (dispatch) => {
    dispatch(resetSelectedVersion());
    try {
      dispatch(resetSelectedVersionSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedVersionFailed());
    }
  };
}

export function clearVersionData() {
  return async (dispatch) => {
    dispatch(resetVersionData());
    try {
      dispatch(resetVersionDataSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetVersionDataFailed());
    }
  };
}

export function resetChangedState() {
  return async (dispatch) => {
    dispatch(updateChangeState());
    try {
      dispatch(updateChangeStateSuccess());
    } catch (error) {
      console.log(error);
      dispatch(updateChangeStateFailed());
    }
  };
}
const sortVersionsByOrder = (versions) => {
  return versions.sort((a, b) => a.versionOrder - b.versionOrder);
};

// only get the bits of the version data needed
const returnStreamlinedVersionData = (version) => {
  return {
    versionId: version._id,
    versionFileName: version.fileName,
    versionWorksheetId: version.worksheet?._id,
    versionName: version.worksheet.name,
    versionLabel: version.worksheet.label,
    versionColour: version.worksheet.colour,
    versionCellColour: version.worksheet.cellColour,
    versionOrder: version.worksheet.order,
    versionPreviousId: version.previous?.worksheet._id,
    versionPreviousFileName: version.previous?.fileName,
    versionPreviousName: version.previous?.worksheet.name,
    versionPreviousLabel: version.previous?.worksheet.label,
    versionPreviousColour: version.previous?.worksheet.colour,
    versionDate: version.updatedAt,
  };
};
