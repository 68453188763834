import { createSlice } from "@reduxjs/toolkit";
import { GridViewRowFieldsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  gridViewRowFields: [],
  selectedGridViewRowField: null,
};

export const gridViewRowFieldsSlice = createSlice({
  name: "gridViewRowFields",
  initialState,
  reducers: {
    changeGridViewRowField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeGridViewRowFieldSuccess: (state, { payload }) => {
      const updatedRowField = returnStreamlinedGridViewData(payload);
      const gridViewRowFieldsCopy = [...state.gridViewRowFields];

      // find field position
      const rfIndex = gridViewRowFieldsCopy.findIndex(
        (rf) => rf.gridViewRowFieldId === updatedRowField.gridViewRowFieldId
      );
      if (rfIndex > -1) {
        // found so update in place
        gridViewRowFieldsCopy[rfIndex] = {
          ...gridViewRowFieldsCopy[rfIndex],
          ...updatedRowField,
        };
        state.gridViewRowFields = [...gridViewRowFieldsCopy];
      } else {
        // it's new
        const withAddedField = [...gridViewRowFieldsCopy, updatedRowField];
        state.gridViewRowFields = [...withAddedField];
      }
      status.stateStatus(status.SUCCESS, state);
    },
    changeGridViewRowFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },

    fetchGridViewRowFields: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchGridViewRowFieldsSuccess: (state, { payload }) => {
      const processedRows = payload.map((vg) =>
        returnStreamlinedGridViewData(vg)
      );
      state.gridViewRowFields = processedRows;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchGridViewRowFieldsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectGridViewRowField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectGridViewRowFieldSuccess: (state, { payload }) => {
      state.selectedGridViewRowField = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectGridViewRowFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeGridViewRowField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeGridViewRowFieldSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedGridViewData(payload);
      const gridViewRowFieldsCopy = [...state.gridViewRowFields];
      const filteredFields = gridViewRowFieldsCopy.filter(
        (f) => f.gridViewRowFieldId !== convertedResponse.gridViewRowFieldId
      );

      state.gridViewRowFields = [...filteredFields];
      status.stateStatus(status.SUCCESS, state);
    },
    removeGridViewRowFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetGridViewRowFields: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetGridViewRowFieldsSuccess: (state) => {
      state.gridViewRowFields = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetGridViewRowFieldsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedGridViewRowField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedGridViewRowFieldSuccess: (state) => {
      state.selectedGridViewRowField = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedGridViewRowFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  changeGridViewRowField,
  changeGridViewRowFieldSuccess,
  changeGridViewRowFieldFailed,
  fetchGridViewRowFields,
  fetchGridViewRowFieldsSuccess,
  fetchGridViewRowFieldsFailed,
  selectGridViewRowField,
  selectGridViewRowFieldSuccess,
  selectGridViewRowFieldFailed,
  removeGridViewRowField,
  removeGridViewRowFieldSuccess,
  removeGridViewRowFieldFailed,
  resetGridViewRowFields,
  resetGridViewRowFieldsSuccess,
  resetGridViewRowFieldsFailed,
  resetSelectedGridViewRowField,
  resetSelectedGridViewRowFieldSuccess,
  resetSelectedGridViewRowFieldFailed,
} = gridViewRowFieldsSlice.actions;

// reducer
export default gridViewRowFieldsSlice.reducer;

// async thunks

// grid views
export function getGridViewRowFields(gridViewId) {
  return async (dispatch) => {
    dispatch(fetchGridViewRowFields());
    try {
      const response = await GridViewRowFieldsAPI.listGridViewRowFields(
        gridViewId
      );
      dispatch(fetchGridViewRowFieldsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchGridViewRowFieldsFailed());
    }
  };
}

export function updateGridViewRowField(formData) {
  return async (dispatch) => {
    dispatch(changeGridViewRowField());
    try {
      const res = await GridViewRowFieldsAPI.updateGridViewRowField(formData);
      dispatch(changeGridViewRowFieldSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeGridViewRowFieldFailed());
    }
  };
}

export function deleteGridViewRowField(gridViewRowFieldId) {
  return async (dispatch) => {
    dispatch(removeGridViewRowField());
    try {
      const res = await GridViewRowFieldsAPI.deleteGridViewRowField(
        gridViewRowFieldId
      );
      dispatch(removeGridViewRowFieldSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeGridViewRowFieldFailed());
    }
  };
}

export function setSelectedGridViewRowField(gridViewRowField) {
  return async (dispatch) => {
    dispatch(selectGridViewRowField());
    try {
      dispatch(selectGridViewRowFieldSuccess(gridViewRowField));
    } catch (error) {
      console.log(error);
      dispatch(selectGridViewRowFieldFailed());
    }
  };
}

export function clearGridViewRowFields() {
  return async (dispatch) => {
    dispatch(resetGridViewRowFields());
    try {
      dispatch(resetGridViewRowFieldsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetGridViewRowFieldsFailed());
    }
  };
}

export function clearSelectedGridViewRowField() {
  return async (dispatch) => {
    dispatch(resetSelectedGridViewRowField());
    try {
      dispatch(resetSelectedGridViewRowFieldSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedGridViewRowFieldFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedGridViewData = (gridViewRowFieldData) => {
  return {
    gridViewRowFieldId: gridViewRowFieldData._id,
    gridViewRowFieldType: gridViewRowFieldData.type,
    gridViewRowFieldValue: gridViewRowFieldData.value,
    gridViewRowFieldReturnField: gridViewRowFieldData.returnField,
    gridViewId: gridViewRowFieldData.gridView._id,
    gridViewRowId: gridViewRowFieldData.gridViewRow._id,
    gridViewColumnId: gridViewRowFieldData.gridViewColumn._id,
    gridViewRowFieldDate: gridViewRowFieldData.updatedAt,
  };
};
