export const SUCCESS = "success";
export const FAILURE = "failure";
export const LOADING = "loading";

export const stateStatus = (statusType, state) => {
  switch (statusType) {
    case SUCCESS:
      state.loading = false;
      state.hasErrors = false;
      break;
    case FAILURE:
      state.loading = false;
      state.hasErrors = true;
      break;
    case LOADING:
      state.loading = true;
      state.hasErrors = false;
      break;
    default:
      return state;
  }
};
