import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { GridViewColumnsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  gridViewColumns: [],
  selectedGridViewColumn: null,
};

export const gridViewColumnsSlice = createSlice({
  name: "gridViewColumns",
  initialState,
  reducers: {
    createGridViewColumn: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createGridViewColumnSuccess: (state, { payload }) => {
      const returnedView = returnStreamlinedGridViewColumnData(payload);
      const gridViewColumnsCopy = state.gridViewColumns;
      const newViews = updateObjectArray(gridViewColumnsCopy, returnedView);

      state.gridViewColumns = newViews;
      status.stateStatus(status.SUCCESS, state);
    },
    createGridViewColumnFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeGridViewColumn: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeGridViewColumnSuccess: (state, { payload }) => {
      const updatedColumn = returnStreamlinedGridViewColumnData(payload);
      const gridViewColumnsCopy = [...state.gridViewColumns];

      // find view position
      const vIndex = gridViewColumnsCopy.findIndex(
        (v) => v.gridViewColumnId === updatedColumn.gridViewColumnId
      );
      // update view in position
      gridViewColumnsCopy[vIndex] = {
        ...gridViewColumnsCopy[vIndex],
        ...updatedColumn,
      };
      state.gridViewColumns = [...gridViewColumnsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeGridViewColumnFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchGridViewColumns: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchGridViewColumnsSuccess: (state, { payload }) => {
      const processedViews = payload.map((vg) =>
        returnStreamlinedGridViewColumnData(vg)
      );
      state.gridViewColumns = processedViews;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchGridViewColumnsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeGridViewColumn: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeGridViewColumnSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedGridViewColumnData(payload);
      const gridViewColumnsCopy = [...state.gridViewColumns];
      const filteredViews = gridViewColumnsCopy.filter(
        (vg) => vg.gridViewColumnId !== convertedResponse.gridViewColumnId
      );
      state.gridViewColumns = filteredViews;
      status.stateStatus(status.SUCCESS, state);
    },
    removeGridViewColumnFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectGridViewColumn: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectGridViewColumnSuccess: (state, { payload }) => {
      state.selectedGridView = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectGridViewColumnFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetGridViewColumns: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetGridViewColumnsSuccess: (state) => {
      state.gridViewColumns = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetGridViewColumnsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedGridViewColumn: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedGridViewColumnSuccess: (state) => {
      state.selectedGridView = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedGridViewColumnFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createGridViewColumn,
  createGridViewColumnSuccess,
  createGridViewColumnFailed,
  changeGridViewColumn,
  changeGridViewColumnSuccess,
  changeGridViewColumnFailed,
  fetchGridViewColumns,
  fetchGridViewColumnsSuccess,
  fetchGridViewColumnsFailed,
  removeGridViewColumn,
  removeGridViewColumnSuccess,
  removeGridViewColumnFailed,
  selectGridViewColumn,
  selectGridViewColumnSuccess,
  selectGridViewColumnFailed,
  resetGridViewColumns,
  resetGridViewColumnsSuccess,
  resetGridViewColumnsFailed,
  resetSelectedGridViewColumn,
  resetSelectedGridViewColumnSuccess,
  resetSelectedGridViewColumnFailed,
} = gridViewColumnsSlice.actions;

// reducer
export default gridViewColumnsSlice.reducer;

// async thunks

// grid views
export function getGridViewColumns(gridViewColumnId) {
  return async (dispatch) => {
    dispatch(fetchGridViewColumns());
    try {
      const response = await GridViewColumnsAPI.listGridViewColumns(
        gridViewColumnId
      );
      dispatch(fetchGridViewColumnsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchGridViewColumnsFailed());
    }
  };
}

export function addGridViewColumn(formData) {
  return async (dispatch) => {
    dispatch(createGridViewColumn());
    try {
      const res = await GridViewColumnsAPI.addGridViewColumn(formData);
      dispatch(createGridViewColumnSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createGridViewColumnFailed());
    }
  };
}

export function updateGridViewColumn(formData) {
  return async (dispatch) => {
    dispatch(changeGridViewColumn());
    try {
      const res = await GridViewColumnsAPI.updateGridViewColumn(formData);
      dispatch(changeGridViewColumnSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeGridViewColumnFailed());
    }
  };
}

export function deleteGridViewColumn(gridViewColumnId) {
  return async (dispatch) => {
    dispatch(removeGridViewColumn());
    try {
      const res = await GridViewColumnsAPI.deleteGridViewColumn(
        gridViewColumnId
      );
      dispatch(removeGridViewColumnSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeGridViewColumnFailed());
    }
  };
}

export function setSelectedGridViewColumn(gridView) {
  return async (dispatch) => {
    dispatch(selectGridViewColumn());
    try {
      dispatch(selectGridViewColumnSuccess(gridView));
    } catch (error) {
      console.log(error);
      dispatch(selectGridViewColumnFailed());
    }
  };
}

export function clearGridViewColumns() {
  return async (dispatch) => {
    dispatch(resetGridViewColumns());
    try {
      dispatch(resetGridViewColumnsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetGridViewColumnsFailed());
    }
  };
}

export function clearSelectedGridViewColumn() {
  return async (dispatch) => {
    dispatch(resetSelectedGridViewColumn());
    try {
      dispatch(resetSelectedGridViewColumnSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedGridViewColumnFailed());
    }
  };
}
// only get the bits of the payload data needed
const returnStreamlinedGridViewColumnData = (gridViewColumnData) => {
  return {
    gridViewColumnId: gridViewColumnData._id,
    gridViewColumnGridViewId: gridViewColumnData.gridView._id,
    gridViewColumnName: gridViewColumnData.name,
    gridViewColumnSubLabel: gridViewColumnData.subLabel,
    gridViewColumnOrder: gridViewColumnData.order,
    gridViewColBgColour: gridViewColumnData.colBgColour,
    gridViewColTextColour: gridViewColumnData.colTextColour,
    gridViewColumnDate: gridViewColumnData.updatedAt,
  };
};
