import apiInstance from "../axiosBase";

export const ViewsAPI = {
  listViews: () => {
    return apiInstance.get(`/config/view/list`);
  },
  View: (id) => {
    return apiInstance.get(`/config/view/${id}`);
  },
  addView: (formData) => {
    return apiInstance.post("/config/view/add", formData);
  },
  updateView: (formData) => {
    return apiInstance.post("/config/view/update", formData);
  },
  deleteView: (id) => {
    return apiInstance.delete(`/config/view/delete/${id}`);
  },
};
