import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectViewGroupsAPI } from "../../API";
import * as status from "./stateStatus";

// TODO: appSate: - https://twitter.com/DavidKPiano/status/972620672743673856?s=20&t=04l-JgTcX-o9OqrpzjMFDg

export const initialState = {
  loading: false,
  hasErrors: false,
  projectViewGroups: [],
  selectedProjectViewGroup: null,
};

export const projectViewGroupSlice = createSlice({
  name: "projectViewGroups",
  initialState,
  reducers: {
    createProjectViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createProjectViewGroupSuccess: (state, { payload }) => {
      const returnedProjectViewGroup =
        returnStreamlinedProjectViewGroupData(payload);
      const projectViewGroupsCopy = state.projectViewGroups;
      const newProjectViewGroup = updateObjectArray(
        projectViewGroupsCopy,
        returnedProjectViewGroup
      );

      state.projectViewGroups = newProjectViewGroup;
      state.selectedProjectViewGroup = returnedProjectViewGroup;
      status.stateStatus(status.SUCCESS, state);
    },
    createProjectViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchProjectViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchProjectViewGroupSuccess: (state, { payload }) => {
      const processedProjectViewGroup = payload.map((pg) =>
        returnStreamlinedProjectViewGroupData(pg)
      );
      state.projectViewGroups = processedProjectViewGroup;
      state.selectedProjectViewGroup = processedProjectViewGroup[0];
      status.stateStatus(status.SUCCESS, state);
    },
    fetchProjectViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeProjectViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeProjectViewGroupSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedProjectViewGroupData(payload);
      const projectViewGroupsCopy = state.projectViewGroups;
      const filteredProjectViewGroup = projectViewGroupsCopy.filter(
        (pg) => pg.projectViewGroupId !== convertedResponse.projectViewGroupId
      );
      state.projectViewGroups = filteredProjectViewGroup;
      status.stateStatus(status.SUCCESS, state);
    },
    removeProjectViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    emptyProjectViewGroups: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    emptyProjectViewGroupsSuccess: (state) => {
      state.projectViewGroups = [];
      status.stateStatus(status.SUCCESS, state);
    },
    emptyProjectViewGroupsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectProjectViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectProjectViewGroupSuccess: (state, { payload }) => {
      state.selectedProjectViewGroup = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectProjectViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectProjectViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectProjectViewGroupSuccess: (state) => {
      state.selectedProjectViewGroup = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectProjectViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createProjectViewGroup,
  createProjectViewGroupSuccess,
  createProjectViewGroupFailed,
  fetchProjectViewGroup,
  fetchProjectViewGroupSuccess,
  fetchProjectViewGroupFailed,
  removeProjectViewGroup,
  removeProjectViewGroupSuccess,
  removeProjectViewGroupFailed,
  emptyProjectViewGroups,
  emptyProjectViewGroupsSuccess,
  emptyProjectViewGroupsFailed,
  selectProjectViewGroup,
  selectProjectViewGroupSuccess,
  selectProjectViewGroupFailed,
  deselectProjectViewGroup,
  deselectProjectViewGroupSuccess,
  deselectProjectViewGroupFailed,
} = projectViewGroupSlice.actions;

// reducer
export default projectViewGroupSlice.reducer;

// async thunks
export function getProjectViewGroups(projectId, type) {
  return async (dispatch) => {
    dispatch(fetchProjectViewGroup());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("type", type);
      const res = await ProjectViewGroupsAPI.listProjectViewGroups(fd);
      dispatch(fetchProjectViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchProjectViewGroupFailed());
    }
  };
}

export function addProjectViewGroup(projectId, viewGroupId, type) {
  return async (dispatch) => {
    dispatch(createProjectViewGroup());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("viewGroupId", viewGroupId);
      fd.append("type", type);
      const res = await ProjectViewGroupsAPI.addProjectViewGroup(fd);
      dispatch(createProjectViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createProjectViewGroupFailed());
    }
  };
}

export function deleteProjectViewGroup(projectViewGroupId, projectId) {
  return async (dispatch) => {
    dispatch(removeProjectViewGroup());
    try {
      const res = await ProjectViewGroupsAPI.deleteProjectViewGroup(
        projectViewGroupId,
        projectId
      );
      dispatch(removeProjectViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeProjectViewGroupFailed());
    }
  };
}

export function setSelectedProjectViewGroup(projectViewGroup) {
  return async (dispatch) => {
    dispatch(selectProjectViewGroup());
    try {
      dispatch(selectProjectViewGroupSuccess(projectViewGroup));
    } catch (error) {
      console.log(error);
      dispatch(selectProjectViewGroupFailed());
    }
  };
}

export function resetSelectedProjectViewGroup() {
  return async (dispatch) => {
    dispatch(deselectProjectViewGroup());
    try {
      dispatch(deselectProjectViewGroupSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectProjectViewGroupFailed());
    }
  };
}

export function clearProjectViewGroups() {
  return async (dispatch) => {
    dispatch(emptyProjectViewGroups());
    try {
      dispatch(emptyProjectViewGroupsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(emptyProjectViewGroupsFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedProjectViewGroupData = (projectViewGroupData) => {
  return {
    projectViewGroupId: projectViewGroupData._id,
    projectViewGroupProjectId: projectViewGroupData.project._id,
    projectViewGroupViewGroupId: projectViewGroupData.viewGroup._id,
    projectViewGroupViewGroupName: projectViewGroupData.viewGroup.name,
    projectViewGroupViewGroupVisible: projectViewGroupData.viewGroup.isVisible,
    projectViewGroupViewGroupDescription:
      projectViewGroupData.viewGroup.description,
  };
};
