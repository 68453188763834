export const trimWhiteSpaceFromArrayItems = (array) => {
  const outPut = [];
  array.forEach((i) => {
    outPut.push(i.trim());
  });
  return outPut;
};

// returns an array of values that are in {} brackets
// e.g. "My string  had {A} in some {B} values"
// would return ["{A}","{B}"]
const getValuesInBrackets = (string) => {
  const fields = string?.match(/\{([^}]+)\}/g);
  return fields;
};

export const stringContainsBrackets = (string) => {
  if (!string) return false;
  const fields = string?.match(/\{([^}]+)\}/g);
  return fields ? fields.length > 0 : false;
};

// returns an string replacing the bracketed content
// "My string  had {A} in some {B} values" would return
// "My string  had contend in some named values"
export const subInStringData = (stringWithBrackets, objectContainingValue) => {
  if (!objectContainingValue) return null;
  const fieldData = getValuesInBrackets(stringWithBrackets);
  if (!fieldData) return null;
  // replace the field with the field value
  fieldData.forEach((f) => {
    const fieldVar = f.replace("{", "").replace("}", "");
    const fieldToSub = objectContainingValue[fieldVar];
    stringWithBrackets = stringWithBrackets.replace(f, fieldToSub);
  });
  return !stringWithBrackets.includes("undefined") ? stringWithBrackets : ""; //stringWithBrackets.replaceAll(/undefined/gi, "");
};

export const preserveTextAreaLineBreaks = (text) => {
  return text.replace(/\n/g, "<br>");
};

export const reinstateTextAreaLineBreaks = (text) => {
  return text.replace(/<br>/g, "\n");
};

export const compareStringValues = (val1, val2) => {
  if (!val1) return true;
  if (!val2) return true;
  const areSame = val1 === val2;
  return areSame;
};
