const environment = {
  env: {
    appUrl: process.env.REACT_APP_API_URL,
    environment: process.env.REACT_APP_ENVIRONMENT,
    nodeEnvironment: process.env.NODE_ENV,
  },
  firebase: {
    apiKey: "AIzaSyD9FfSwS57_iNc3xk5ftbn9U6HIBfTXVD4",
    authDomain: "jsmwp-1f9af.firebaseapp.com",
    projectId: "jsmwp-1f9af",
    storageBucket: "jsmwp-1f9af.appspot.com",
    messagingSenderId: "824743167441",
    appId: "1:824743167441:web:e2f0fdc9672ffc542d4cf0",
    measurementId: "G-2WMJDWDFJ5",
  },
};

export default environment;
