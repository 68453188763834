import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import isToday from "dayjs/plugin/isToday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";

// implement the plugins
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.extend(weekOfYear);
dayjs.extend(isSameOrAfter);
dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.tz.setDefault("Europe/London");

// for the file time
export const formatDateWithTime = (date) => {
  return dayjs(date).format("D MMM YY HH:mm:ss");
};

export const formatDateFriendly = (date) => {
  // spreadsheet messes with the date by storing time as 23:00
  // so parsed an hour behind
  // adding a couple of hours is hacky but helps for the time being
  return dayjs(date).add(2, "hours").format("D MMM YY");
};

export const formatDateForExcel = (date) => {
  return dayjs(date).add(2, "hours").format("DD/MM/YYYY");
};

export const formatDateForGrid = (date) => {
  return dayjs(date).add(2, "hours").format("DD MMM");
};

export const checkToday = (date) => {
  return dayjs(date).isToday();
};

export const checkWeekend = (day) => {
  if (day?.toLowerCase() === "sat" || day?.toLowerCase() === "sun") {
    return true;
  }
  return false;
};

export const weekNumber = (date) => {
  return dayjs(date).week();
};

export const dayNow = () => {
  return dayjs().format("ddd D MMM YYYY");
};

export const timeNow = () => {
  return dayjs().format("HH:mm");
};

export const checkBankHoliday = (bankHolidays, dateToCheck) => {
  const d = new Date(dateToCheck).toDateString();
  const i = bankHolidays.findIndex((e) => {
    return e === d;
  });
  return i > -1;
};

export const isDate = (string) => {
  if (typeof string === "number") return false;
  return dayjs(string).isValid();
};

export const checkDateValid = (string) => {
  if (typeof string === "number") return false;
  return dayjs(string, "YYYY-MM-DD", true).isValid();
};

export const isSameOrAfterToday = (date) => {
  // need to add time as Excel outputting the date weirdly
  const excelAdjustedDate = dayjs(date).add(2, "hours");
  const now = dayjs();
  const checkSameAfter = dayjs(excelAdjustedDate).isSameOrAfter(now);
  return checkSameAfter;
};

export const dateIsBetween = (checkDate, startDate, endDate) => {
  if (!checkDate) return false;
  if (!startDate) return false;
  if (!endDate) return false;
  return dayjs(checkDate).isBetween(startDate, endDate, "day", "[]");
};
