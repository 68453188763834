import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";
import ForgottenPassword from "./Authentication/ForgottenPassword/ForgottenPassword";
import Login from "./Authentication/Login/Login";
import ProtectedRoute from "./Authentication/ProtectedRoute/ProtectedRoute";

const Config = lazy(() => import("../Components/Config/Config"));
const ViewGroups = lazy(() =>
  import("../Components/Config/ViewGroups/ViewGroups")
);
const Views = lazy(() => import("../Components/Config/Views/Views"));
const Rooms = lazy(() => import("../Components/Config/Rooms/Rooms"));
const Projects = lazy(() => import("../Components/Projects/Projects"));
const Worksheets = lazy(() =>
  import("../Components/Config/Worksheets/Worksheets")
);
const GridViews = lazy(() =>
  import("../Components/Config/GridViews/GridViews")
);
const ProjectParams = lazy(() =>
  import("../Components/ProjectParams/ProjectParams")
);
const ProjectConfig = lazy(() =>
  import("../Components/ProjectConfig/ProjectConfig")
);
const ProjectData = lazy(() => import("../Components/ProjectData/ProjectData"));
const ProjectView = lazy(() => import("../Components/ProjectView/ProjectView"));

const App = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: (
        <ProtectedRoute>
          <Projects />
        </ProtectedRoute>
      ),
      children: [
        {
          path: "projects",
          element: (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "setup",
      element: (
        <ProtectedRoute>
          <Config />
        </ProtectedRoute>
      ),
    },
    {
      path: "setup/rooms",
      element: (
        <ProtectedRoute>
          <Rooms />
        </ProtectedRoute>
      ),
    },
    {
      path: "setup/worksheets",
      element: (
        <ProtectedRoute>
          <Worksheets />
        </ProtectedRoute>
      ),
    },
    {
      path: "setup/viewgroups",
      element: (
        <ProtectedRoute>
          <ViewGroups />
        </ProtectedRoute>
      ),
    },
    {
      path: "setup/views",
      element: (
        <ProtectedRoute>
          <Views />
        </ProtectedRoute>
      ),
    },
    {
      path: "setup/gridviews",
      element: (
        <ProtectedRoute>
          <GridViews />
        </ProtectedRoute>
      ),
    },
    {
      path: "project/params",
      element: (
        <ProtectedRoute>
          <ProjectParams />
        </ProtectedRoute>
      ),
    },
    {
      path: "project/config",
      element: (
        <ProtectedRoute>
          <ProjectConfig />
        </ProtectedRoute>
      ),
    },
    {
      path: "project/data",
      element: (
        <ProtectedRoute>
          <ProjectData />
        </ProtectedRoute>
      ),
    },
    {
      path: "project/data/view",
      element: (
        <ProtectedRoute>
          <ProjectView />
        </ProtectedRoute>
      ),
    },
    { path: "/login", element: <Login /> },
    { path: "/forgotten-password", element: <ForgottenPassword /> },
    {
      path: "*",
      element: (
        <ProtectedRoute>
          <Projects />
        </ProtectedRoute>
      ),
    },
  ]);

  let returnLayout = <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>;

  return returnLayout;
};

export default App;
