import apiInstance from "../axiosBase";

export const GridViewRowFieldsAPI = {
  listGridViewRowFields: (id) => {
    return apiInstance.get(`/config/gridviewrowfield/list/${id}`);
  },
  updateGridViewRowField: (formData) => {
    return apiInstance.post("/config/gridviewrowfield/update", formData);
  },
  deleteGridViewRowField: (id) => {
    return apiInstance.delete(`/config/gridviewrowfield/delete/${id}`);
  },
};
