import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  changed: true,
  projects: [],
  selectedProject: null,
};

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {
    fetchProjectList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    fetchProjectListSuccess: (state, { payload }) => {
      // only get the bits of the payload data needed
      const mappedProjects = payload.map((p) =>
        returnStreamlinedProjectData(p)
      );
      state.projects = mappedProjects;
      state.changed = false;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchProjectListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeProjectList: (state) => {
      state.loading = true;
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    changeProjectListSuccess: (state, { payload }) => {
      const addedProject = returnStreamlinedProjectData(payload);
      const projectsCopy = state.projects;
      const updateProjectsList = updateObjectArray(projectsCopy, addedProject);
      state.projects = updateProjectsList;
      state.loading = false;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    changeProjectListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeProject: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    changeProjectSuccess: (state, { payload }) => {
      const updatedProject = returnStreamlinedProjectData(payload);
      const projectsCopy = [...state.projects];
      // find index
      const pIndex = projectsCopy.findIndex(
        (p) => p.projectId === updatedProject.projectId
      );
      // updated in place
      projectsCopy[pIndex] = {
        ...projectsCopy[pIndex],
        ...updatedProject,
      };
      state.projects = [...projectsCopy];
      state.selectedProject = updatedProject;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    changeProjectFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    removeProjectFromList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    removeProjectFromListSuccess: (state, { payload }) => {
      const stateCopy = state.projects;
      const newArr = stateCopy.filter((p) => p.projectId !== payload);
      state.projects = newArr;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    removeProjectFromListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    changeSelectedProject: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeSelectedProjectSuccess: (state, { payload }) => {
      state.selectedProject = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    changeSelectedProjectFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetProjectList: (state) => {
      state.changed = false;
      status.stateStatus(status.LOADING, state);
    },
    resetProjectListSuccess: (state) => {
      state.projects = [];
      state.selectedProject = null;
      state.changed = true;
      status.stateStatus(status.SUCCESS, state);
    },
    resetProjectListFailed: (state) => {
      state.changed = false;
      status.stateStatus(status.FAILURE, state);
    },
    resetSelectedProject: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetSelectedProjectSuccess: (state) => {
      state.selectedProject = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetSelectedProjectFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  fetchProjectList,
  fetchProjectListSuccess,
  fetchProjectListFailed,
  changeProjectList,
  changeProjectListSuccess,
  changeProjectListFailed,
  changeProject,
  changeProjectSuccess,
  changeProjectFailed,
  removeProjectFromList,
  removeProjectFromListSuccess,
  removeProjectFromListFailed,
  changeSelectedProject,
  changeSelectedProjectSuccess,
  changeSelectedProjectFailed,
  resetProjectList,
  resetProjectListSuccess,
  resetProjectListFailed,
  resetSelectedProject,
  resetSelectedProjectSuccess,
  resetSelectedProjectFailed,
} = projectSlice.actions;

// reducer
export default projectSlice.reducer;

// async thunks
export function getProjects() {
  return async (dispatch) => {
    dispatch(fetchProjectList());
    try {
      const response = await ProjectAPI.list();
      dispatch(fetchProjectListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchProjectListFailed());
    }
  };
}

// async thunks
export function getProjectById(projectId) {
  return async (dispatch) => {
    dispatch(changeSelectedProject());
    try {
      const response = await ProjectAPI.detail(projectId);
      dispatch(changeSelectedProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeSelectedProjectFailed());
    }
  };
}

export function addProject(formData) {
  return async (dispatch) => {
    dispatch(changeProjectList());
    try {
      const response = await ProjectAPI.add(formData);
      dispatch(changeProjectListSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectListFailed());
    }
  };
}

export function updateProject(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.update(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectListFailed());
    }
  };
}

export function updateProjectExcelDefaults(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.updateExcel(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function resetProjectExcelDefaults(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.resetExcel(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function updateProjectCalendarDefaults(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.updateCalendar(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function resetProjectCalendarDefaults(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.resetCalendar(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function updateNonWorkingDayDefault(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.updateNonWorkingDay(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function resetNonWorkingDayDefault(formData) {
  return async (dispatch) => {
    dispatch(changeProject());
    try {
      const response = await ProjectAPI.resetNonWorkingDay(formData);
      dispatch(changeProjectSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changeProjectFailed());
    }
  };
}

export function deleteProject(id) {
  return async (dispatch) => {
    dispatch(removeProjectFromList());
    try {
      await ProjectAPI.delete(id);
      dispatch(removeProjectFromListSuccess(id));
    } catch (error) {
      console.log(error);
      dispatch(removeProjectFromListFailed());
    }
  };
}

export function updateSelectedProject(project) {
  return async (dispatch) => {
    dispatch(changeSelectedProject());
    try {
      dispatch(changeSelectedProjectSuccess(project));
    } catch (error) {
      console.log(error);
      dispatch(changeSelectedProjectFailed());
    }
  };
}

export function resetProjects() {
  return async (dispatch) => {
    dispatch(resetProjectList());
    try {
      dispatch(resetProjectListSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetProjectListFailed());
    }
  };
}

export function resetProject() {
  return async (dispatch) => {
    dispatch(resetSelectedProject());
    try {
      dispatch(resetSelectedProjectSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetSelectedProjectFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedProjectData = (project) => {
  return {
    projectId: project._id,
    projectName: project.name,
    projectDescription: project.description,
    projectDate: project.updatedAt,
    projectStartDate: project.start,
    projectEndDate: project.end,
    projectHeaderField: project.headerField,
    projectFooterField: project.footerField,
    projectHeaderFieldLabel: project.headerFieldLabel,
    projectFooterFieldLabel: project.footerFieldLabel,
    projectDayField: project.dayField,
    projectDateField: project.dateField,
    projectNonWorkingDayField: project.nonWorkingDay,
  };
};
