import { combineReducers } from "redux";

import holidayReducer from "./bankHolidaySlice";
import configReducer from "./configSlice";
import viewGroupReducer from "./viewGroupSlice";
import projectViewGroupReducer from "./projectViewGroupSlice";
import projectViewGroupViewsReducer from "./projectViewGroupViewsSlice";
import projectViewGroupViewRoomsReducer from "./projectViewGroupViewRoomsSlice";
import viewReducer from "./viewSlice";
import projectReducer from "./projectSlice";
import roomReducer from "./roomSlice";
import roomFieldReducer from "./roomFieldSlice";
import worksheetReducer from "./worksheetSlice";
import versionReducer from "./versionSlice";
import projectPeopleReducer from "./projectPeopleSlice";
import gridViewsReducer from "./gridViewSlice";
import gridViewColumnsReducer from "./gridViewColumnsSlice";
import gridViewRowsReducer from "./gridViewRowsSlice";
import gridViewRowFieldsReducer from "./gridViewRowFieldsSlice";
import projectGridViewReducer from "./projectGridViewSlice";
import gridViewProjectPeopleReducer from "./gridViewProjectPeopleSlice";

const rootReducer = combineReducers({
  bankHoliday: holidayReducer,
  viewGroups: viewGroupReducer,
  projectViewGroups: projectViewGroupReducer,
  projectViewGroupViews: projectViewGroupViewsReducer,
  projectViewGroupViewRooms: projectViewGroupViewRoomsReducer,
  projectGridViews: projectGridViewReducer,
  views: viewReducer,
  rooms: roomReducer,
  roomFields: roomFieldReducer,
  config: configReducer,
  versions: versionReducer,
  projects: projectReducer,
  worksheets: worksheetReducer,
  projectPeople: projectPeopleReducer,
  gridViews: gridViewsReducer,
  gridViewColumns: gridViewColumnsReducer,
  gridViewRows: gridViewRowsReducer,
  gridViewRowFields: gridViewRowFieldsReducer,
  gridViewProjectPeople: gridViewProjectPeopleReducer,
});

export default rootReducer;

// holidays
export { getBankHolidays } from "./bankHolidaySlice";
// config
export { getConfig, setConfigScreen } from "./configSlice";
export {
  addViewGroup,
  deleteViewGroup,
  getViewGroups,
  updateViewGroup,
  clearViewGroups,
  updateViewGroupDefault,
  updateViewGroupVisability,
} from "./viewGroupSlice";
export {
  addProjectViewGroup,
  deleteProjectViewGroup,
  getProjectViewGroups,
  clearProjectViewGroups,
  setSelectedProjectViewGroup,
  resetSelectedProjectViewGroup,
} from "./projectViewGroupSlice";
export {
  addProjectViewGroupView,
  clearProjectViewGroupViews,
  deleteProjectViewGroupView,
  getProjectViewGroupViews,
  setSelectedProjectViewGroupView,
  resetSelectedProjectViewGroupView,
} from "./projectViewGroupViewsSlice";
export {
  addProjectViewGroupViewRoom,
  clearProjectViewGroupViewRooms,
  deleteProjectViewGroupViewRoom,
  getProjectViewGroupViewRooms,
  resetSelectedProjectViewGroupViewRoom,
  setSelectedProjectViewGroupViewRoom,
} from "./projectViewGroupViewRoomsSlice";
export {
  addView,
  deleteView,
  getViews,
  updateView,
  clearViews,
} from "./viewSlice";
export {
  addRoom,
  deleteRoom,
  getRooms,
  updateRoom,
  setSelectedRoom,
  clearRooms,
  resetSelectedRoom,
  duplicateRoom,
} from "./roomSlice";
export {
  addRoomField,
  deleteRoomField,
  getRoomFields,
  updateRoomField,
  clearRoomFields,
  getFieldsForAllRooms,
} from "./roomFieldSlice";
export {
  addWorksheet,
  deleteWorksheet,
  getWorksheets,
  setSelectedWorksheet,
  updateWorksheet,
  clearWorksheets,
  resetSelectedWorksheet,
} from "./worksheetSlice";
export {
  getProjects,
  addProject,
  updateProject,
  updateProjectExcelDefaults,
  resetProjectExcelDefaults,
  updateProjectCalendarDefaults,
  resetProjectCalendarDefaults,
  updateSelectedProject,
  deleteProject,
  resetProject,
  resetProjects,
  getProjectById,
  updateNonWorkingDayDefault,
  resetNonWorkingDayDefault,
} from "./projectSlice";
export {
  addVersion,
  deleteVersion,
  getVersionById,
  getVersions,
  resetVersion,
  resetVersions,
  updateSelectedVersion,
  setPreviousVersion,
  getCurrentVersionData,
  getPreviousVersionData,
  clearVersionData,
  resetChangedState,
} from "./versionSlice";
export {
  addPerson,
  clearPeople,
  deletePerson,
  getPeople,
  updatePerson,
} from "./projectPeopleSlice";
export {
  addGridView,
  deleteGridView,
  getGridViews,
  updateGridView,
  setSelectedGridView,
  clearGridViews,
  clearSelectedGridView,
  duplicateGridView,
} from "./gridViewSlice";
export {
  addGridViewColumn,
  clearGridViewColumns,
  clearSelectedGridViewColumn,
  deleteGridViewColumn,
  getGridViewColumns,
  setSelectedGridViewColumn,
  updateGridViewColumn,
} from "./gridViewColumnsSlice";
export {
  addGridViewRow,
  clearGridViewRows,
  clearSelectedGridViewRow,
  deleteGridViewRow,
  getGridViewRows,
  setSelectedGridViewRow,
  updateGridViewRow,
} from "./gridViewRowsSlice";
export {
  clearGridViewRowFields,
  clearSelectedGridViewRowField,
  getGridViewRowFields,
  setSelectedGridViewRowField,
  updateGridViewRowField,
  deleteGridViewRowField,
} from "./gridViewRowFieldsSlice";
export {
  addProjectGridView,
  clearProjectGridViews,
  deleteProjectGridView,
  getProjectGridViews,
  resetSelectedProjectGridView,
  setSelectedProjectGridView,
} from "./projectGridViewSlice";
export {
  addGridViewProjectPerson,
  clearGridViewProjectPeople,
  deleteGridViewProjectPerson,
  getGridViewProjectPeople,
} from "./gridViewProjectPeopleSlice";
