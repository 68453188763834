import apiInstance from "../axiosBase";

export const RoomFieldsAPI = {
  listRoomFields: (roomId) => {
    return apiInstance.get(`/config/room/field/list/${roomId}`);
  },
  roomField: (id) => {
    return apiInstance.get(`/config/room/field/${id}`);
  },
  addRoomField: (formData) => {
    return apiInstance.post("/config/room/field/add", formData);
  },
  updateRoomField: (formData) => {
    return apiInstance.post("/config/room/field/update", formData);
  },
  deleteRoomField: (id) => {
    return apiInstance.delete(`/config/room/field/delete/${id}`);
  },
};
