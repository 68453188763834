import apiInstance from "./axiosBase";

export const GridViewProjectPeopleAPI = {
  listPeople: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post("/project/config/gridViewPeople/list/", formData);
  },
  addPerson: (formData) => {
    return apiInstance.post("/project/config/gridViewPeople/add", formData);
  },
  deletePerson: (id) => {
    return apiInstance.delete(`/project/config/gridViewPeople/delete/${id}`);
  },
};
