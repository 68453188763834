import apiInstance from "./axiosBase";

export const ProjectViewGroupsAPI = {
  listProjectViewGroups: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post("/project/config/projectviewgroup/list/", formData);
  },
  projectViewGroup: (id) => {
    return apiInstance.get(`/project/config/projectviewgroup/${id}`);
  },
  addProjectViewGroup: (formData) => {
    return apiInstance.post("/project/config/projectviewgroup/add", formData);
  },
  deleteProjectViewGroup: (id, projectId) => {
    return apiInstance.delete(`/project/config/projectviewgroup/delete`, {
      data: { id, projectId },
    });
  },
};
