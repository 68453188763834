import { createRoot } from "react-dom/client";
import App from "./Components/App";
import { BrowserRouter as Router } from "react-router-dom";
import "@fontsource/dancing-script";
import { Provider } from "react-redux";
import store from "./App/store/store";
import "./index.css";

const app = (
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(app);
