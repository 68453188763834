import apiInstance from "../axiosBase";

export const GridViewColumnsAPI = {
  listGridViewColumns: (id) => {
    return apiInstance.get(`/config/gridviewcolumn/list/${id}`);
  },
  gridViewColumn: (id) => {
    return apiInstance.get(`/config/gridviewcolumn/${id}`);
  },
  addGridViewColumn: (formData) => {
    return apiInstance.post("/config/gridviewcolumn/add", formData);
  },
  updateGridViewColumn: (formData) => {
    return apiInstance.post("/config/gridviewcolumn/update", formData);
  },
  deleteGridViewColumn: (id) => {
    return apiInstance.delete(`/config/gridviewcolumn/delete/${id}`);
  },
};
