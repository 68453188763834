import apiInstance from "../axiosBase";

export const WorksheetsAPI = {
  listWorksheets: () => {
    return apiInstance.get(`/config/worksheet/list`);
  },
  worksheet: (id) => {
    return apiInstance.get(`/config/worksheet/${id}`);
  },
  addWorksheet: (formData) => {
    return apiInstance.post("/config/worksheet/add", formData);
  },
  updateWorksheet: (formData) => {
    return apiInstance.post("/config/worksheet/update", formData);
  },
  deleteWorksheet: (id) => {
    return apiInstance.delete(`/config/worksheet/delete/${id}`);
  },
};
