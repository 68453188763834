import clsx from "clsx";

const TextButton = ({
  buttonText,
  buttonClick,
  buttonType,
  additionalStyle,
  styleOverride = null,
  isWarning = false,
}) => {
  const displayBGColour = () => {
    if (!styleOverride) {
      return false;
    }
    return true;
  };

  return (
    <button
      type={buttonType ? buttonType : "button"}
      className={clsx(
        additionalStyle,
        styleOverride
          ? styleOverride
          : "w-auto px-4 py-2 mt-3 text-sm font-medium text-white border border-transparent rounded-md shadow-sm",
        displayBGColour
          ? isWarning
            ? "bg-red-600 hover:bg-red-700"
            : "bg-green-600 hover:bg-green-700"
          : null
      )}
      onClick={buttonClick}
    >
      {buttonText}
    </button>
  );
};

export default TextButton;
