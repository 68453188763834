import apiInstance from "../axiosBase";

export const GridViewRowsAPI = {
  listGridViewRows: (id) => {
    return apiInstance.get(`/config/gridviewrow/list/${id}`);
  },
  gridViewRow: (id) => {
    return apiInstance.get(`/config/gridviewrow/${id}`);
  },
  addGridViewRow: (formData) => {
    return apiInstance.post("/config/gridviewrow/add", formData);
  },
  updateGridViewRow: (formData) => {
    return apiInstance.post("/config/gridviewrow/update", formData);
  },
  deleteGridViewRow: (id) => {
    return apiInstance.delete(`/config/gridviewrow/delete/${id}`);
  },
};
