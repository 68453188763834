import apiInstance from "./axiosBase";

export const ProjectAPI = {
  list: () => {
    return apiInstance.get(`/project/list`);
  },
  detail: (projectId) => {
    return apiInstance.get(`/project/list/${projectId}`);
  },
  add: (formData) => {
    return apiInstance.post("/project/add", formData);
  },
  update: (formData) => {
    return apiInstance.post("/project/update", formData);
  },
  updateExcel: (formData) => {
    return apiInstance.post("/project/update/excelfields", formData);
  },
  resetExcel: (formData) => {
    return apiInstance.post("/project/reset/excelfields", formData);
  },
  updateCalendar: (formData) => {
    return apiInstance.post("/project/update/calendarfields", formData);
  },
  resetCalendar: (formData) => {
    return apiInstance.post("/project/reset/calendarfields", formData);
  },
  updateNonWorkingDay: (formData) => {
    return apiInstance.post("/project/update/nonworkingday", formData);
  },
  resetNonWorkingDay: (formData) => {
    return apiInstance.post("/project/reset/nonworkingday", formData);
  },
  delete: (id) => {
    return apiInstance.delete(`/project/delete/${id}`);
  },
  resetDayByDay: (formData) => {
    return apiInstance.post(`/project/reset/resetDayByDay`, formData);
  },
  resetByEpisode: (formData) => {
    return apiInstance.post(`/project/reset/resetByEpisode`, formData);
  },
  resetMilestones: (formData) => {
    return apiInstance.post(`/project/reset/resetMilestones`, formData);
  },
  resetAllConfig: (formData) => {
    return apiInstance.post(`/project/reset/resetOutputConfig`, formData);
  },
};
