import apiInstance from "../axiosBase";

export const GridViewsAPI = {
  listGridViews: () => {
    return apiInstance.get(`/config/gridview/list`);
  },
  gridView: (id) => {
    return apiInstance.get(`/config/gridview/${id}`);
  },
  addGridView: (formData) => {
    return apiInstance.post("/config/gridview/add", formData);
  },
  updateGridView: (formData) => {
    return apiInstance.post("/config/gridview/update", formData);
  },
  duplicateGridView: (formData) => {
    return apiInstance.post("/config/gridview/duplicate", formData);
  },
  deleteGridView: (id) => {
    return apiInstance.delete(`/config/gridview/delete/${id}`);
  },
};
