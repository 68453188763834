import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ViewsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  views: [],
};

export const viewSlice = createSlice({
  name: "views",
  initialState,
  reducers: {
    createView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createViewSuccess: (state, { payload }) => {
      const returnedView = returnStreamlinedViewData(payload);
      const viewsCopy = state.views;
      const newViews = updateObjectArray(viewsCopy, returnedView);

      state.views = newViews;
      status.stateStatus(status.SUCCESS, state);
    },
    createViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeViewSuccess: (state, { payload }) => {
      const updatedView = returnStreamlinedViewData(payload);
      const viewsCopy = [...state.views];

      // find view position
      const vIndex = viewsCopy.findIndex(
        (v) => v.viewId === updatedView.viewId
      );
      // update view in position
      viewsCopy[vIndex] = {
        ...viewsCopy[vIndex],
        ...updatedView,
      };
      state.views = [...viewsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchViewsSuccess: (state, { payload }) => {
      const processedViews = payload.map((vg) => returnStreamlinedViewData(vg));
      state.views = processedViews;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeViewSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedViewData(payload);
      const viewsCopy = state.views;
      const filteredViews = viewsCopy.filter(
        (vg) => vg.viewId !== convertedResponse.viewId
      );
      state.views = filteredViews;
      status.stateStatus(status.SUCCESS, state);
    },
    removeViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetViewsSuccess: (state) => {
      state.views = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createView,
  createViewSuccess,
  createViewFailed,
  changeView,
  changeViewSuccess,
  changeViewFailed,
  fetchViews,
  fetchViewsSuccess,
  fetchViewsFailed,
  removeView,
  removeViewSuccess,
  removeViewFailed,
  resetViews,
  resetViewsSuccess,
  resetViewsFailed,
} = viewSlice.actions;

// reducer
export default viewSlice.reducer;

// async thunks

// view groups
export function getViews() {
  return async (dispatch) => {
    dispatch(fetchViews());
    try {
      const response = await ViewsAPI.listViews();
      dispatch(fetchViewsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchViewsFailed());
    }
  };
}

export function addView(formData) {
  return async (dispatch) => {
    dispatch(createView());
    try {
      const res = await ViewsAPI.addView(formData);
      dispatch(createViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createViewFailed());
    }
  };
}

export function updateView(formData) {
  return async (dispatch) => {
    dispatch(changeView());
    try {
      const res = await ViewsAPI.updateView(formData);
      dispatch(changeViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeViewFailed());
    }
  };
}

export function deleteView(viewId) {
  return async (dispatch) => {
    dispatch(removeView());
    try {
      const res = await ViewsAPI.deleteView(viewId);
      dispatch(removeViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeViewFailed());
    }
  };
}

export function clearViews() {
  return async (dispatch) => {
    dispatch(resetViews());
    try {
      dispatch(resetViewsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetViewsFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedViewData = (viewData) => {
  return {
    viewId: viewData._id,
    viewName: viewData.name,
    viewDescription: viewData.description,
    viewType: viewData.type,
    viewDate: viewData.updatedAt,
  };
};
