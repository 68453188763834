import { createSlice } from "@reduxjs/toolkit";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  configSet: false,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    fetchConfig: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchConfigSuccess: (state) => {
      status.stateStatus(status.SUCCESS, state);
    },
    fetchConfigFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    setConfig: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    setConfigSuccess: (state, { payload }) => {
      state.configSet = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    setConfigFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  fetchConfig,
  fetchConfigSuccess,
  fetchConfigFailed,
  setConfig,
  setConfigSuccess,
  setConfigFailed,
} = configSlice.actions;

// reducer
export default configSlice.reducer;

// async thunks
export function getConfig() {
  return async (dispatch) => {
    dispatch(fetchConfig());
    try {
      dispatch(fetchConfigSuccess());
    } catch (error) {
      console.log(error);
      dispatch(fetchConfigFailed());
    }
  };
}

export function setConfigScreen(isSet) {
  return async (dispatch) => {
    dispatch(setConfig());
    try {
      dispatch(setConfigSuccess(isSet));
    } catch (error) {
      console.log(error);
      dispatch(setConfigFailed());
    }
  };
}
