export const objectSwitch = (data, type) => {
  return { ...data }[type];
};

// https://ultimatecourses.com/blog/deprecating-the-switch-statement-for-object-literals
export const setModalTailwindWidthClass = (width) => {
  const widths = {
    xs: "max-w-xs",
    sm: "max-w-sm",
    md: "max-w-md",
    lg: "max-w-lg",
    xl: "max-w-xl",
    "2xl": "max-w-2xl",
    "3xl": "max-w-3xl",
    "4xl": "max-w-4xl",
    "5xl": "max-w-5xl",
    "6xl": "max-w-6xl",
    "7xl": "max-w-7xl",
    min: "max-w-min",
    max: "max-w-max",
    fit: "max-w-fit",
    default: "max-w-fit",
  };

  return objectSwitch(widths, width) || objectSwitch(widths, "default");
};

export const setModalIconSize = (size) => {
  const sizes = {
    small: { width: "w-4", height: "h-4" },
    medium: { width: "w-5", height: "h-5" },
    large: { width: "w-6", height: "h-6" },
    default: { width: "w-5", height: "h-5" },
  };

  return objectSwitch(sizes, size) || objectSwitch(sizes, "default");
};
