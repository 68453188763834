import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectPeopleAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  projectPeople: [],
};

export const projectPeopleSlice = createSlice({
  name: "projectPeople",
  initialState,
  reducers: {
    fetchPeople: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchPeopleSuccess: (state, { payload }) => {
      // only get the bits of the payload data needed
      const mappedPerson = payload.map((p) => returnStreamlinedPersonData(p));
      state.projectPeople = mappedPerson;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchPeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changePeople: (state) => {
      state.loading = true;
      status.stateStatus(status.LOADING, state);
    },
    changePeopleSuccess: (state, { payload }) => {
      const addedPerson = returnStreamlinedPersonData(payload);
      const projectPeopleCopy = state.projectPeople;
      const updatePeopleList = updateObjectArray(
        projectPeopleCopy,
        addedPerson
      );
      state.projectPeople = updatePeopleList;
      state.loading = false;
      status.stateStatus(status.SUCCESS, state);
    },
    changePeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changePerson: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changePersonSuccess: (state, { payload }) => {
      const updatedPerson = returnStreamlinedPersonData(payload);
      const projectPeopleCopy = [...state.projectPeople];
      // find index
      const pIndex = projectPeopleCopy.findIndex(
        (p) => p.personId === updatedPerson.personId
      );
      // updated in place
      projectPeopleCopy[pIndex] = {
        ...projectPeopleCopy[pIndex],
        ...updatedPerson,
      };
      state.projectPeople = [...projectPeopleCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changePersonFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removePersonFromList: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removePersonFromListSuccess: (state, { payload }) => {
      const stateCopy = state.projectPeople;
      const newArr = stateCopy.filter((p) => p.personId !== payload);
      state.projectPeople = newArr;
      status.stateStatus(status.SUCCESS, state);
    },
    removePersonFromListFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetPeople: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetPeopleSuccess: (state) => {
      state.projectPeople = [];
      // state.selectedPerson = null;
      status.stateStatus(status.SUCCESS, state);
    },
    resetPeopleFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  fetchPeople,
  fetchPeopleSuccess,
  fetchPeopleFailed,
  changePeople,
  changePeopleSuccess,
  changePeopleFailed,
  changePerson,
  changePersonSuccess,
  changePersonFailed,
  removePersonFromList,
  removePersonFromListSuccess,
  removePersonFromListFailed,
  resetPeople,
  resetPeopleSuccess,
  resetPeopleFailed,
} = projectPeopleSlice.actions;

// reducer
export default projectPeopleSlice.reducer;

// async thunks
export function getPeople(projectId) {
  return async (dispatch) => {
    dispatch(fetchPeople(projectId));
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      const response = await ProjectPeopleAPI.list(fd);
      dispatch(fetchPeopleSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchPeopleFailed());
    }
  };
}

export function addPerson(formData) {
  return async (dispatch) => {
    dispatch(changePeople());
    try {
      const response = await ProjectPeopleAPI.addPerson(formData);
      dispatch(changePeopleSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changePeopleFailed());
    }
  };
}

export function updatePerson(formData) {
  return async (dispatch) => {
    dispatch(changePeople());
    try {
      const response = await ProjectPeopleAPI.updatePerson(formData);
      dispatch(changePersonSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(changePeopleFailed());
    }
  };
}

export function deletePerson(id) {
  return async (dispatch) => {
    dispatch(removePersonFromList());
    try {
      await ProjectPeopleAPI.deletePerson(id);
      dispatch(removePersonFromListSuccess(id));
    } catch (error) {
      console.log(error);
      dispatch(removePersonFromListFailed());
    }
  };
}

export function clearPeople() {
  return async (dispatch) => {
    dispatch(resetPeople());
    try {
      dispatch(resetPeopleSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetPeopleFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedPersonData = (person) => {
  return {
    personId: person._id,
    personProjectId: person.project._id,
    personName: person.name,
    personRole: person.role,
    personTel: person.tel,
    personEmail: person.email,
  };
};
