import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { RoomsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  rooms: [],
  selectedRoom: null,
};

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    createRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createRoomSuccess: (state, { payload }) => {
      const returnedRoom = returnStreamlinedRoomData(payload);
      const roomsCopy = state.rooms;
      const newRooms = updateObjectArray(roomsCopy, returnedRoom);

      state.rooms = newRooms;
      status.stateStatus(status.SUCCESS, state);
    },
    createRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeRoomSuccess: (state, { payload }) => {
      const updatedRoom = returnStreamlinedRoomData(payload);
      const roomsCopy = [...state.rooms];
      // get room index
      const rIndex = roomsCopy.findIndex(
        (r) => r.roomId === updatedRoom.roomId
      );
      // update in place
      roomsCopy[rIndex] = {
        ...roomsCopy[rIndex],
        ...updatedRoom,
      };
      state.rooms = [...roomsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchRooms: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchRoomsSuccess: (state, { payload }) => {
      const processedRooms = payload.map((vg) => returnStreamlinedRoomData(vg));
      state.rooms = processedRooms;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchRoomsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeRoomSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedRoomData(payload);
      const roomsCopy = state.rooms;
      const filteredRooms = roomsCopy.filter(
        (vg) => vg.roomId !== convertedResponse.roomId
      );
      state.rooms = filteredRooms;
      status.stateStatus(status.SUCCESS, state);
    },
    removeRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectRoomSuccess: (state, { payload }) => {
      state.selectedRoom = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    clearRoomData: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    clearRoomDataSuccess: (state) => {
      state.rooms = [];
      status.stateStatus(status.SUCCESS, state);
    },
    clearRoomDataFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectRoom: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectRoomSuccess: (state) => {
      state.selectedRoom = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectRoomFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createRoom,
  createRoomSuccess,
  createRoomFailed,
  changeRoom,
  changeRoomSuccess,
  changeRoomFailed,
  fetchRooms,
  fetchRoomsSuccess,
  fetchRoomsFailed,
  removeRoom,
  removeRoomSuccess,
  removeRoomFailed,
  selectRoom,
  selectRoomSuccess,
  selectRoomFailed,
  clearRoomData,
  clearRoomDataSuccess,
  clearRoomDataFailed,
  deselectRoom,
  deselectRoomSuccess,
  deselectRoomFailed,
} = roomSlice.actions;

// reducer
export default roomSlice.reducer;

// async thunks
export function getRooms() {
  return async (dispatch) => {
    dispatch(fetchRooms());
    try {
      const response = await RoomsAPI.listRooms();
      dispatch(fetchRoomsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchRoomsFailed());
    }
  };
}

export function addRoom(formData) {
  return async (dispatch) => {
    dispatch(createRoom());
    try {
      const res = await RoomsAPI.addRoom(formData);
      dispatch(createRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createRoomFailed());
    }
  };
}

export function updateRoom(formData) {
  return async (dispatch) => {
    dispatch(changeRoom());
    try {
      const res = await RoomsAPI.updateRoom(formData);
      dispatch(changeRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeRoomFailed());
    }
  };
}

export function duplicateRoom(formData) {
  return async (dispatch) => {
    dispatch(createRoom());
    try {
      const res = await RoomsAPI.duplicateRoom(formData);
      dispatch(createRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createRoomFailed());
    }
  };
}

export function deleteRoom(roomId) {
  return async (dispatch) => {
    dispatch(removeRoom());
    try {
      const res = await RoomsAPI.deleteRoom(roomId);
      dispatch(removeRoomSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeRoomFailed());
    }
  };
}

export function setSelectedRoom(room) {
  return async (dispatch) => {
    dispatch(selectRoom());
    try {
      dispatch(selectRoomSuccess(room));
    } catch (error) {
      console.log(error);
      dispatch(selectRoomFailed());
    }
  };
}

export function resetSelectedRoom() {
  return async (dispatch) => {
    dispatch(deselectRoom());
    try {
      dispatch(deselectRoomSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectRoomFailed());
    }
  };
}

export function clearRooms() {
  return async (dispatch) => {
    dispatch(clearRoomData());
    try {
      dispatch(clearRoomDataSuccess());
    } catch (error) {
      console.log(error);
      dispatch(clearRoomDataFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedRoomData = (roomData) => {
  return {
    roomId: roomData._id,
    roomName: roomData.name,
    roomDescription: roomData.description,
    roomType: roomData.type,
    roomDate: roomData.updatedAt,
  };
};
