import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ProjectViewGroupViewsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  projectViewGroupViews: [],
  selectedProjectViewGroupView: null,
  // appSate: - https://twitter.com/DavidKPiano/status/972620672743673856?s=20&t=04l-JgTcX-o9OqrpzjMFDg
};

export const projectViewGroupViewSlice = createSlice({
  name: "projectViewGroupViews",
  initialState,
  reducers: {
    createProjectViewGroupView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createProjectViewGroupViewSuccess: (state, { payload }) => {
      const returnedProjectView =
        returnStreamlinedProjectViewGroupViewData(payload);
      const projectViewGroupViewsCopy = state.projectViewGroupViews;
      const newProjectView = updateObjectArray(
        projectViewGroupViewsCopy,
        returnedProjectView
      );

      state.projectViewGroupViews = newProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    createProjectViewGroupViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchProjectViewGroupViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchProjectViewGroupViewsSuccess: (state, { payload }) => {
      const processedProjectView = payload.map((pg) =>
        returnStreamlinedProjectViewGroupViewData(pg)
      );
      state.projectViewGroupViews = processedProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchProjectViewGroupViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeProjectViewGroupView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeProjectViewGroupViewSuccess: (state, { payload }) => {
      const convertedResponse =
        returnStreamlinedProjectViewGroupViewData(payload);
      const projectViewGroupViewsCopy = state.projectViewGroupViews;
      const filteredProjectView = projectViewGroupViewsCopy.filter(
        (pg) =>
          pg.projectViewGroupViewId !== convertedResponse.projectViewGroupViewId
      );
      state.projectViewGroupViews = filteredProjectView;
      status.stateStatus(status.SUCCESS, state);
    },
    removeProjectViewGroupViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    emptyProjectViewGroupViews: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    emptyProjectViewGroupViewsSuccess: (state) => {
      state.projectViewGroupViews = [];
      status.stateStatus(status.SUCCESS, state);
    },
    emptyProjectViewGroupViewsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectProjectViewGroupView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectProjectViewGroupViewSuccess: (state, { payload }) => {
      state.selectedProjectViewGroupView = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectProjectViewGroupViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectProjectViewGroupView: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectProjectViewGroupViewSuccess: (state) => {
      state.selectedProjectViewGroupView = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectProjectViewGroupViewFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createProjectViewGroupView,
  createProjectViewGroupViewSuccess,
  createProjectViewGroupViewFailed,
  fetchProjectViewGroupViews,
  fetchProjectViewGroupViewsSuccess,
  fetchProjectViewGroupViewsFailed,
  removeProjectViewGroupView,
  removeProjectViewGroupViewSuccess,
  removeProjectViewGroupViewFailed,
  emptyProjectViewGroupViews,
  emptyProjectViewGroupViewsSuccess,
  emptyProjectViewGroupViewsFailed,
  selectProjectViewGroupView,
  selectProjectViewGroupViewSuccess,
  selectProjectViewGroupViewFailed,
  deselectProjectViewGroupView,
  deselectProjectViewGroupViewSuccess,
  deselectProjectViewGroupViewFailed,
} = projectViewGroupViewSlice.actions;

// reducer
export default projectViewGroupViewSlice.reducer;

// async thunks
export function getProjectViewGroupViews(projectViewGroupId) {
  return async (dispatch) => {
    dispatch(fetchProjectViewGroupViews());
    try {
      const fd = new FormData();
      fd.append("projectViewGroupId", projectViewGroupId);
      const res = await ProjectViewGroupViewsAPI.listProjectViewGroupViews(fd);
      dispatch(fetchProjectViewGroupViewsSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchProjectViewGroupViewsFailed());
    }
  };
}

export function addProjectViewGroupView(projectId, projectViewGroupId, viewId) {
  return async (dispatch) => {
    dispatch(createProjectViewGroupView());
    try {
      const fd = new FormData();
      fd.append("projectId", projectId);
      fd.append("projectViewGroupId", projectViewGroupId);
      fd.append("viewId", viewId);
      const res = await ProjectViewGroupViewsAPI.addProjectViewGroupView(fd);
      dispatch(createProjectViewGroupViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createProjectViewGroupViewFailed());
    }
  };
}

export function deleteProjectViewGroupView(projectViewGroupViewId, projectId) {
  return async (dispatch) => {
    dispatch(removeProjectViewGroupView());
    try {
      const res = await ProjectViewGroupViewsAPI.deleteProjectViewGroupView(
        projectViewGroupViewId,
        projectId
      );
      dispatch(removeProjectViewGroupViewSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeProjectViewGroupViewFailed());
    }
  };
}

export function setSelectedProjectViewGroupView(projectViewGroupView) {
  return async (dispatch) => {
    dispatch(selectProjectViewGroupView());
    try {
      dispatch(selectProjectViewGroupViewSuccess(projectViewGroupView));
    } catch (error) {
      console.log(error);
      dispatch(selectProjectViewGroupViewFailed());
    }
  };
}

export function resetSelectedProjectViewGroupView() {
  return async (dispatch) => {
    dispatch(deselectProjectViewGroupView());
    try {
      dispatch(deselectProjectViewGroupViewSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectProjectViewGroupViewFailed());
    }
  };
}

export function clearProjectViewGroupViews() {
  return async (dispatch) => {
    dispatch(emptyProjectViewGroupViews());
    try {
      dispatch(emptyProjectViewGroupViewsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(emptyProjectViewGroupViewsFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedProjectViewGroupViewData = (
  projectViewGroupViewData
) => {
  return {
    projectViewGroupViewId: projectViewGroupViewData._id,
    projectViewGroupViewViewId: projectViewGroupViewData.view._id,
    projectViewGroupViewViewName: projectViewGroupViewData.view.name,
    projectViewGroupViewViewDescription:
      projectViewGroupViewData.view.description,
  };
};
