import { createSlice } from "@reduxjs/toolkit";
import { HolidaysAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  holidays: [],
};

export const bankHolidaySlice = createSlice({
  name: "bankHoliday",
  initialState,
  reducers: {
    fetchHolidays: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchHolidaysSuccess: (state, { payload }) => {
      const listOfDates = [];
      payload.events.forEach((e) => {
        listOfDates.push(new Date(e.date).toDateString());
      });
      state.holidays = listOfDates;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchHolidaysFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const { fetchHolidays, fetchHolidaysSuccess, fetchHolidaysFailed } =
  bankHolidaySlice.actions;

// reducer
export default bankHolidaySlice.reducer;

// async thunks
export function getBankHolidays() {
  return async (dispatch) => {
    dispatch(fetchHolidays());
    try {
      const holidays = await HolidaysAPI.bankHolidays();
      dispatch(fetchHolidaysSuccess(holidays.data));
    } catch (error) {
      dispatch(fetchHolidaysFailed());
    }
  };
}
