const isNumber = (value) => /^\d+$/.test(value);
// convert any int type values in the object
// to proper int values before sending

export const convertIntegersInAnObject = (obj) => {
  const res = {};
  for (let [key, value] of Object.entries(obj)) {
    res[key] = parseInt(value);
    if (isNaN(parseInt(value)) || !isNumber(value)) {
      res[key] = value;
    }
  }
  return res;
};

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export const updateObjectArray = (oldArray, addedObject) => {
  return [...oldArray, addedObject];
};
export const updateObjectInArray = (
  initialArray,
  objectToUpdate,
  updatedProperties
) => {
  // array copy
  const newArray = initialArray?.length ? [...initialArray] : initialArray;
  // get the item position
  const itemIndex = newArray.findIndex((i) => {
    return i.id === objectToUpdate.id;
  });

  // found item
  if (itemIndex > -1) {
    // update the item in that position
    newArray[itemIndex] = { ...newArray[itemIndex], ...updatedProperties };
    return newArray;
  }
  // not found so add item
  newArray.push(updatedProperties);
  return newArray;
};

export const fieldExists = (object, field) => {
  if (!object) return false;
  if (!Object.hasOwn(object, field)) return false;
  return true;
};
