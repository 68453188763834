import apiInstance from "./axiosBase";

export const ProjectViewGroupViewsAPI = {
  listProjectViewGroupViews: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post(
      "/project/config/projectviewgroupview/list/",
      formData
    );
  },
  projectViewGroupView: (id) => {
    return apiInstance.get(`/project/config/projectviewgroupview/${id}`);
  },
  addProjectViewGroupView: (formData) => {
    return apiInstance.post(
      "/project/config/projectviewgroupview/add",
      formData
    );
  },
  deleteProjectViewGroupView: (id, projectId) => {
    return apiInstance.delete(`/project/config/projectviewgroupview/delete/`, {
      data: { id, projectId },
    });
  },
};
