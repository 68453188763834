import {
  clearGridViewColumns,
  clearGridViewProjectPeople,
  clearGridViewRowFields,
  clearGridViewRows,
  clearGridViews,
  clearPeople,
  clearProjectGridViews,
  clearProjectViewGroups,
  clearProjectViewGroupViewRooms,
  clearProjectViewGroupViews,
  clearRoomFields,
  clearRooms,
  clearVersionData,
  clearViewGroups,
  clearViews,
  clearWorksheets,
  resetProject,
  resetProjects,
  resetSelectedProjectViewGroup,
  resetSelectedProjectViewGroupView,
  resetSelectedProjectViewGroupViewRoom,
  resetSelectedRoom,
  resetSelectedWorksheet,
  resetVersion,
  resetVersions,
} from "../App/store/slices";

export const clearVersionCache = (dispatch) => {
  dispatch(resetVersions());
  dispatch(resetVersion());
  dispatch(clearVersionData());
};

export const clearProjectCache = (dispatch) => {
  dispatch(resetProject());
  dispatch(resetProjects());
};

export const clearProjectConfigCache = (dispatch) => {
  dispatch(resetSelectedProjectViewGroupViewRoom());
  dispatch(clearProjectViewGroupViewRooms());
  dispatch(resetSelectedProjectViewGroupView());
  dispatch(clearProjectViewGroupViews());
  dispatch(resetSelectedProjectViewGroup());
  dispatch(clearProjectViewGroups());
  dispatch(clearProjectGridViews());
  dispatch(clearGridViewColumns());
  dispatch(clearGridViewRows());
  dispatch(clearGridViewRowFields());
  dispatch(clearGridViewProjectPeople());
  dispatch(clearPeople());
};

export const clearConfigCache = (dispatch) => {
  dispatch(clearRoomFields());
  dispatch(resetSelectedRoom());
  dispatch(clearRooms);
  dispatch(clearViewGroups());
  dispatch(clearViews());
  dispatch(resetSelectedWorksheet());
  dispatch(clearWorksheets());
  dispatch(clearGridViews());
  dispatch(clearGridViewColumns());
  dispatch(clearGridViewRows());
  dispatch(clearGridViewRowFields());
  dispatch(clearGridViewProjectPeople());
  dispatch(clearPeople());
};
