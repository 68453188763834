import { createSlice } from "@reduxjs/toolkit";
import { updateObject } from "../../../utils";
import { RoomFieldsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  roomFields: [],
};

export const roomFieldSlice = createSlice({
  name: "roomFields",
  initialState,
  reducers: {
    createRoomField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createRoomFieldSuccess: (state, { payload }) => {
      const returnedRoomField = returnStreamlinedRoomFieldData(payload);

      const newRoomFields = state?.roomFields
        ? [...state.roomFields, returnedRoomField]
        : [returnedRoomField];
      state.roomFields = sortRoomFieldsByRoomNumber(newRoomFields);
      status.stateStatus(status.SUCCESS, state);
    },
    createRoomFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeRoomField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeRoomFieldSuccess: (state, { payload }) => {
      const updatedRoomField = returnStreamlinedRoomFieldData(payload);
      const roomFieldsCopy = [...state.roomFields];

      // find index
      const rfIndex = roomFieldsCopy.findIndex(
        (rf) => rf.roomFieldId === updatedRoomField.roomFieldId
      );
      // update in place
      roomFieldsCopy[rfIndex] = {
        ...roomFieldsCopy[rfIndex],
        ...updateObject(updatedRoomField, { saved: true }),
      };
      state.roomFields = sortRoomFieldsByRoomNumber([...roomFieldsCopy]);
      status.stateStatus(status.SUCCESS, state);
    },
    changeRoomFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchRoomFields: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchRoomFieldsSuccess: (state, { payload }) => {
      const processedRoomFields = payload.map((vg) =>
        returnStreamlinedRoomFieldData(vg)
      );
      state.roomFields = processedRoomFields;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchRoomFieldsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchAllRoomFields: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchAllRoomFieldsSuccess: (state, { payload }) => {
      const fieldsForRoom = [];
      [...payload].forEach((roomFields) => {
        [...roomFields].forEach((rf) => {
          fieldsForRoom.push(returnStreamlinedRoomFieldData(rf));
        });
      });

      state.roomFields = fieldsForRoom;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchAllRoomFieldsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeRoomField: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeRoomFieldSuccess: (state, { payload }) => {
      const deletedRoomField = returnStreamlinedRoomFieldData(payload);
      const roomFieldsCopy = state.roomFields;
      const filteredRoomFields = roomFieldsCopy.filter(
        (vg) => vg.roomFieldId !== deletedRoomField.roomFieldId
      );
      state.roomFields = sortRoomFieldsByRoomNumber(filteredRoomFields);
      status.stateStatus(status.SUCCESS, state);
    },
    removeRoomFieldFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    clearRoomFieldData: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    clearRoomFieldDataSuccess: (state) => {
      state.roomFields = [];
      status.stateStatus(status.SUCCESS, state);
    },
    clearRoomFieldDataFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createRoomField,
  createRoomFieldSuccess,
  createRoomFieldFailed,
  changeRoomField,
  changeRoomFieldSuccess,
  changeRoomFieldFailed,
  fetchRoomFields,
  fetchRoomFieldsSuccess,
  fetchRoomFieldsFailed,
  fetchAllRoomFields,
  fetchAllRoomFieldsSuccess,
  fetchAllRoomFieldsFailed,
  removeRoomField,
  removeRoomFieldSuccess,
  removeRoomFieldFailed,
  clearRoomFieldData,
  clearRoomFieldDataSuccess,
  clearRoomFieldDataFailed,
} = roomFieldSlice.actions;

// reducer
export default roomFieldSlice.reducer;

// async thunks
export function getRoomFields(roomId) {
  return async (dispatch) => {
    dispatch(fetchRoomFields());
    try {
      const response = await RoomFieldsAPI.listRoomFields(roomId);
      dispatch(fetchRoomFieldsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchRoomFieldsFailed());
    }
  };
}

export function getFieldsForAllRooms(roomIds) {
  return async (dispatch) => {
    dispatch(fetchAllRoomFields());
    try {
      const roomData = [];
      for (const id of roomIds) {
        const response = await RoomFieldsAPI.listRoomFields(id);
        if (response.data.length) {
          roomData.push(response.data);
        }
      }
      dispatch(fetchAllRoomFieldsSuccess(roomData));
    } catch (error) {
      console.log(error);
      dispatch(fetchAllRoomFieldsFailed());
    }
  };
}

export function addRoomField(roomId, fieldNumber) {
  return async (dispatch) => {
    dispatch(createRoomField());
    try {
      // find latest field number before sending
      const fd = new FormData();
      fd.append("roomId", roomId);
      fd.append("fieldNumber", fieldNumber);
      const res = await RoomFieldsAPI.addRoomField(fd);
      dispatch(createRoomFieldSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createRoomFieldFailed());
    }
  };
}

export function updateRoomField(roomField) {
  return async (dispatch) => {
    dispatch(changeRoomField());
    try {
      const fd = new FormData();
      fd.append("id", roomField.roomFieldId);
      fd.append("label", roomField.roomFieldLabel);
      fd.append("dataField", roomField.roomFieldDataField);
      fd.append("fontWeight", roomField.roomFieldFontWeight);
      fd.append("showLabel", roomField.roomFieldShowLabel);
      fd.append("inEvent", roomField.roomFieldEvent);
      const res = await RoomFieldsAPI.updateRoomField(fd);
      dispatch(changeRoomFieldSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeRoomFieldFailed());
    }
  };
}

export function deleteRoomField(roomFieldId) {
  return async (dispatch) => {
    dispatch(removeRoomField());
    try {
      const res = await RoomFieldsAPI.deleteRoomField(roomFieldId);
      dispatch(removeRoomFieldSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeRoomFieldFailed());
    }
  };
}

export function clearRoomFields() {
  return async (dispatch) => {
    dispatch(clearRoomFieldData());
    try {
      dispatch(clearRoomFieldDataSuccess());
    } catch (error) {
      console.log(error);
      dispatch(clearRoomFieldDataFailed());
    }
  };
}

const sortRoomFieldsByRoomNumber = (roomFields) => {
  return roomFields.sort((a, b) => a.roomFieldNumber - b.roomFieldNumber);
};

// only get the bits of the payload data needed
const returnStreamlinedRoomFieldData = (roomFieldData) => {
  return {
    roomFieldId: roomFieldData._id,
    roomFieldLabel: roomFieldData.label,
    roomFieldDataField: roomFieldData.dataField,
    roomFieldFontWeight: roomFieldData.fontWeight,
    roomFieldShowLabel: roomFieldData.showLabel,
    roomFieldEvent: roomFieldData.inEvent,
    roomFieldNumber: roomFieldData.fieldNumber,
    roomFieldRoomId: roomFieldData.room._id,
    roomFieldRoomName: roomFieldData.room.name,
    roomFieldDate: roomFieldData.updatedAt,
  };
};
