// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  // createUserWithEmailAndPassword,
} from "firebase/auth";
import environment from "./environment";

// Initialize Firebase
const app = initializeApp(environment.firebase);
const auth = getAuth(app);
const SECONDS_TO_ADD = 3300; // 3300 for 55 mins

const loginWithEmailAndPassword = async (email, password) => {
  try {
    const usr = await signInWithEmailAndPassword(auth, email, password);
    const tkn = await usr.user.getIdToken();
    // timeout in 55 mins
    const timeOut = new Date().getTime() + 3300 * 1000;
    localStorage.setItem("token", tkn);
    localStorage.setItem("tokenTimeout", timeOut);
    return true;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

// register user
// const registerWithEmailAndPassword = async (name, email, password) => {
//   try {
//     const res = await createUserWithEmailAndPassword(auth, email, password);
//     const user = res.user;
//     await addDoc(collection(db, "users"), {
//       uid: user.uid,
//       name,
//       authProvider: "local",
//       email,
//     });
//   } catch (err) {
//     console.error(err);
//     alert(err.message);
//   }
// };

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (error) {
    console.error(error);
    return error.message;
  }
};

const logout = () => {
  signOut(auth);
  localStorage.removeItem("token");
  localStorage.removeItem("tokenTimeout");
};

const refreshUser = async () => {
  if (!auth) return;
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenTimeout");
    const tkn = await auth.currentUser.getIdToken(true);
    const timeOut = new Date().getTime() + SECONDS_TO_ADD * 1000;
    localStorage.setItem("token", tkn);
    localStorage.setItem("tokenTimeout", timeOut);
    return;
  } catch (error) {
    console.log(error);
    return;
  }
};

export {
  auth,
  // registerWithEmailAndPassword,
  loginWithEmailAndPassword,
  logout,
  sendPasswordReset,
  refreshUser,
};
