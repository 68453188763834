import apiInstance from "../axiosBase";

export const RoomsAPI = {
  listRooms: () => {
    return apiInstance.get(`/config/room/list`);
  },
  room: (id) => {
    return apiInstance.get(`/config/room/${id}`);
  },
  addRoom: (formData) => {
    return apiInstance.post("/config/room/add", formData);
  },
  updateRoom: (formData) => {
    return apiInstance.post("/config/room/update", formData);
  },
  deleteRoom: (id) => {
    return apiInstance.delete(`/config/room/delete/${id}`);
  },
  duplicateRoom: (formData) => {
    return apiInstance.post("/config/room/duplicate", formData);
  },
};
