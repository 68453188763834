import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./slices";
import environment from "../config/environment";

const store = configureStore({
  reducer: rootReducer,
  devTools: environment.env.nodeEnvironment !== "production",
});

export default store;
