import apiInstance from "./axiosBase";

export const ProjectViewGroupViewRoomsAPI = {
  listProjectViewGroupViewRooms: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post(
      "/project/config/projectviewgroupviewroom/list/",
      formData
    );
  },
  projectViewGroupViewRoom: (id) => {
    return apiInstance.get(`/project/config/projectviewgroupviewroom/${id}`);
  },
  addProjectViewGroupViewRoom: (formData) => {
    return apiInstance.post(
      "/project/config/projectviewgroupviewroom/add",
      formData
    );
  },
  deleteProjectViewGroupViewRoom: (id) => {
    return apiInstance.delete(
      `/project/config/projectviewgroupviewroom/delete/${id}`
    );
  },
};
