import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { WorksheetsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  worksheets: [],
  selectedWorksheet: null,
};

export const worksheetSlice = createSlice({
  name: "worksheet",
  initialState,
  reducers: {
    createWorksheet: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createWorksheetSuccess: (state, { payload }) => {
      const returnedWorksheet = returnStreamlinedWorksheetData(payload);
      const worksheetCopy = state.worksheets;
      const newWorksheets = updateObjectArray(worksheetCopy, returnedWorksheet);

      state.worksheets = sortWorksheetsByOrder(newWorksheets);
      status.stateStatus(status.SUCCESS, state);
    },
    createWorksheetFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeWorksheet: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeWorksheetSuccess: (state, { payload }) => {
      const updatedWorksheet = returnStreamlinedWorksheetData(payload);
      const worksheetCopy = [...state.worksheets];
      // get worksheet index
      const wsIndex = worksheetCopy.findIndex(
        (ws) => ws.worksheetId === updatedWorksheet.worksheetId
      );
      // updated in place
      worksheetCopy[wsIndex] = {
        ...worksheetCopy[wsIndex],
        ...updatedWorksheet,
      };
      state.worksheets = sortWorksheetsByOrder([...worksheetCopy]);
      status.stateStatus(status.SUCCESS, state);
    },
    changeWorksheetFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchWorksheets: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchWorksheetsSuccess: (state, { payload }) => {
      const processedWorksheets = payload.map((vg) =>
        returnStreamlinedWorksheetData(vg)
      );
      state.worksheets = processedWorksheets;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchWorksheetsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeWorksheet: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeWorksheetSuccess: (state, { payload }) => {
      const convertedResponse = returnStreamlinedWorksheetData(payload);
      const worksheetCopy = state.worksheets;
      const filteredWorksheets = worksheetCopy.filter(
        (vg) => vg.worksheetId !== convertedResponse.worksheetId
      );
      state.worksheets = sortWorksheetsByOrder(filteredWorksheets);
      status.stateStatus(status.SUCCESS, state);
    },
    removeWorksheetFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetWorksheets: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetWorksheetsSuccess: (state) => {
      state.worksheets = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetWorksheetsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    selectWorksheet: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    selectWorksheetSuccess: (state, { payload }) => {
      state.selectedWorksheet = payload;
      status.stateStatus(status.SUCCESS, state);
    },
    selectWorksheetFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    deselectWorksheet: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    deselectWorksheetSuccess: (state) => {
      state.selectedWorksheet = null;
      status.stateStatus(status.SUCCESS, state);
    },
    deselectWorksheetFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createWorksheet,
  createWorksheetSuccess,
  createWorksheetFailed,
  changeWorksheet,
  changeWorksheetSuccess,
  changeWorksheetFailed,
  fetchWorksheets,
  fetchWorksheetsSuccess,
  fetchWorksheetsFailed,
  removeWorksheet,
  removeWorksheetSuccess,
  removeWorksheetFailed,
  resetWorksheets,
  resetWorksheetsSuccess,
  resetWorksheetsFailed,
  selectWorksheet,
  selectWorksheetSuccess,
  selectWorksheetFailed,
  deselectWorksheet,
  deselectWorksheetSuccess,
  deselectWorksheetFailed,
} = worksheetSlice.actions;

// reducer
export default worksheetSlice.reducer;

// async thunks
export function getWorksheets() {
  return async (dispatch) => {
    dispatch(fetchWorksheets());
    try {
      const response = await WorksheetsAPI.listWorksheets();
      dispatch(fetchWorksheetsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchWorksheetsFailed());
    }
  };
}

export function addWorksheet(formData) {
  return async (dispatch) => {
    dispatch(createWorksheet());
    try {
      const res = await WorksheetsAPI.addWorksheet(formData);
      dispatch(createWorksheetSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createWorksheetFailed());
    }
  };
}

export function updateWorksheet(formData) {
  return async (dispatch) => {
    dispatch(changeWorksheet());
    try {
      const res = await WorksheetsAPI.updateWorksheet(formData);
      dispatch(changeWorksheetSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeWorksheetFailed());
    }
  };
}

export function deleteWorksheet(worksheetId) {
  return async (dispatch) => {
    dispatch(removeWorksheet());
    try {
      const res = await WorksheetsAPI.deleteWorksheet(worksheetId);
      dispatch(removeWorksheetSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeWorksheetFailed());
    }
  };
}

export function setSelectedWorksheet(worksheet) {
  return async (dispatch) => {
    dispatch(selectWorksheet());
    try {
      dispatch(selectWorksheetSuccess(worksheet));
    } catch (error) {
      console.log(error);
      dispatch(selectWorksheetFailed());
    }
  };
}

export function clearWorksheets() {
  return async (dispatch) => {
    dispatch(resetWorksheets());
    try {
      dispatch(resetWorksheetsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetWorksheetsFailed());
    }
  };
}

export function resetSelectedWorksheet(worksheet) {
  return async (dispatch) => {
    dispatch(deselectWorksheet());
    try {
      dispatch(deselectWorksheetSuccess());
    } catch (error) {
      console.log(error);
      dispatch(deselectWorksheetFailed());
    }
  };
}

const sortWorksheetsByOrder = (worksheets) => {
  return worksheets.sort((a, b) => a.worksheetOrder - b.worksheetOrder);
};

// only get the bits of the payload data needed
const returnStreamlinedWorksheetData = (worksheetData) => {
  return {
    worksheetId: worksheetData._id,
    worksheetName: worksheetData.name,
    worksheetLabel: worksheetData.label,
    worksheetColour: worksheetData.colour,
    worksheetCellColour: worksheetData.cellColour,
    worksheetHeaderRows: worksheetData.headerRows,
    worksheetOrder: worksheetData.order,
    worksheetDate: worksheetData.updatedAt,
  };
};
