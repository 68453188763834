import apiInstance from "./axiosBase";

export const ProjectGridViewsAPI = {
  listProjectGridViews: (formData) => {
    // don't forget it's a post for sending data
    return apiInstance.post("/project/config/projectgridview/list/", formData);
  },
  projectGridView: (id) => {
    return apiInstance.get(`/project/config/projectgridview/${id}`);
  },
  addProjectGridView: (formData) => {
    return apiInstance.post("/project/config/projectgridview/add", formData);
  },
  deleteProjectGridView: (id) => {
    return apiInstance.delete(`/project/config/projectgridview/delete/${id}`);
  },
};
