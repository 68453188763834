import { createSlice } from "@reduxjs/toolkit";
import { updateObjectArray } from "../../../utils";
import { ViewGroupsAPI } from "../../API";
import * as status from "./stateStatus";

export const initialState = {
  loading: false,
  hasErrors: false,
  viewGroups: [],
};

export const viewGroupSlice = createSlice({
  name: "viewGroups",
  initialState,
  reducers: {
    createViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    createViewGroupSuccess: (state, { payload }) => {
      const returnedViewGroup = returnStreamlinedViewGroupData(payload);
      const vgCopy = state.viewGroups;
      const newVG = updateObjectArray(vgCopy, returnedViewGroup);

      state.viewGroups = newVG;
      status.stateStatus(status.SUCCESS, state);
    },
    createViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    changeViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    changeViewGroupSuccess: (state, { payload }) => {
      const updatedViewGroup = returnStreamlinedViewGroupData(payload);
      const viewGroupsCopy = [...state.viewGroups];
      // find position of vg
      const vgIndex = viewGroupsCopy.findIndex(
        (vg) => vg.viewGroupId === updatedViewGroup.viewGroupId
      );
      // update vg in same position
      viewGroupsCopy[vgIndex] = {
        ...viewGroupsCopy[vgIndex],
        ...updatedViewGroup,
      };
      state.viewGroups = [...viewGroupsCopy];
      status.stateStatus(status.SUCCESS, state);
    },
    changeViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    fetchViewGroups: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    fetchViewGroupsSuccess: (state, { payload }) => {
      const processedViewGroups = payload.map((vg) =>
        returnStreamlinedViewGroupData(vg)
      );
      state.viewGroups = processedViewGroups;
      status.stateStatus(status.SUCCESS, state);
    },
    fetchViewGroupsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    removeViewGroup: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    removeViewGroupSuccess: (state, { payload }) => {
      const delVG = returnStreamlinedViewGroupData(payload);
      const viewGroupsCopy = state.viewGroups;
      const filteredViewGroups = viewGroupsCopy.filter(
        (vg) => vg.viewGroupId !== delVG.viewGroupId
      );
      state.viewGroups = filteredViewGroups;
      status.stateStatus(status.SUCCESS, state);
    },
    removeViewGroupFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
    resetViewGroups: (state) => {
      status.stateStatus(status.LOADING, state);
    },
    resetViewGroupsSuccess: (state) => {
      state.viewGroups = [];
      status.stateStatus(status.SUCCESS, state);
    },
    resetViewGroupsFailed: (state) => {
      status.stateStatus(status.FAILURE, state);
    },
  },
});

// actions
export const {
  createViewGroup,
  createViewGroupSuccess,
  createViewGroupFailed,
  changeViewGroup,
  changeViewGroupSuccess,
  changeViewGroupFailed,
  fetchViewGroups,
  fetchViewGroupsSuccess,
  fetchViewGroupsFailed,
  removeViewGroup,
  removeViewGroupSuccess,
  removeViewGroupFailed,
  resetViewGroups,
  resetViewGroupsSuccess,
  resetViewGroupsFailed,
} = viewGroupSlice.actions;

// reducer
export default viewGroupSlice.reducer;

// async thunks
export function getViewGroups() {
  return async (dispatch) => {
    dispatch(fetchViewGroups());
    try {
      const response = await ViewGroupsAPI.listViewGroups();
      dispatch(fetchViewGroupsSuccess(response.data));
    } catch (error) {
      console.log(error);
      dispatch(fetchViewGroupsFailed());
    }
  };
}

export function addViewGroup(formData) {
  return async (dispatch) => {
    dispatch(createViewGroup());
    try {
      const res = await ViewGroupsAPI.addViewGroup(formData);
      dispatch(createViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(createViewGroupFailed());
    }
  };
}

export function updateViewGroup(formData) {
  return async (dispatch) => {
    dispatch(changeViewGroup());
    try {
      const res = await ViewGroupsAPI.updateViewGroup(formData);
      dispatch(changeViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeViewGroupFailed());
    }
  };
}

export function updateViewGroupVisability(viewGroupId, isVisible) {
  return async (dispatch) => {
    dispatch(changeViewGroup());
    try {
      const fd = new FormData();
      fd.append("id", viewGroupId);
      fd.append("isVisible", isVisible);
      const res = await ViewGroupsAPI.changeViewGroupVisibility(fd);
      dispatch(changeViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeViewGroupFailed());
    }
  };
}

export function updateViewGroupDefault(viewGroupId, isDefault) {
  return async (dispatch) => {
    dispatch(changeViewGroup());
    try {
      const fd = new FormData();
      fd.append("id", viewGroupId);
      fd.append("isDefault", isDefault);
      const res = await ViewGroupsAPI.changeViewGroupDefault(fd);
      dispatch(changeViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(changeViewGroupFailed());
    }
  };
}

export function deleteViewGroup(viewGroupId) {
  return async (dispatch) => {
    dispatch(removeViewGroup());
    try {
      const res = await ViewGroupsAPI.deleteViewGroup(viewGroupId);
      dispatch(removeViewGroupSuccess(res.data));
    } catch (error) {
      console.log(error);
      dispatch(removeViewGroupFailed());
    }
  };
}

export function clearViewGroups() {
  return async (dispatch) => {
    dispatch(resetViewGroups());
    try {
      dispatch(resetViewGroupsSuccess());
    } catch (error) {
      console.log(error);
      dispatch(resetViewGroupsFailed());
    }
  };
}

// only get the bits of the payload data needed
const returnStreamlinedViewGroupData = (viewGroupData) => {
  return {
    viewGroupId: viewGroupData._id,
    viewGroupName: viewGroupData.name,
    viewGroupDescription: viewGroupData.description,
    viewGroupType: viewGroupData.type,
    viewGroupVisibility: viewGroupData.isVisible,
    viewGroupDefault: viewGroupData.isDefault,
    viewGroupDate: viewGroupData.updatedAt,
  };
};
