import apiInstance from "../axiosBase";

export const ViewGroupsAPI = {
  listViewGroups: () => {
    return apiInstance.get(`/config/viewgroup/list`);
  },
  viewGroup: (id) => {
    return apiInstance.get(`/config/viewgroup/${id}`);
  },
  addViewGroup: (formData) => {
    return apiInstance.post("/config/viewgroup/add", formData);
  },
  updateViewGroup: (formData) => {
    return apiInstance.post("/config/viewgroup/update", formData);
  },
  changeViewGroupVisibility: (formData) => {
    return apiInstance.post("/config/viewgroup/visibility", formData);
  },
  changeViewGroupDefault: (formData) => {
    return apiInstance.post("/config/viewgroup/default", formData);
  },
  deleteViewGroup: (id) => {
    return apiInstance.delete(`/config/viewgroup/delete/${id}`);
  },
};
