import { formatDateForExcel, formatDateFriendly } from "./dateConverter";

// sort the data into week format
export const mapDataToWeeks = (excelData) => {
  // split into weeks (7 day chunks)
  const weekList = [];
  let counter = 0;
  excelData.forEach((w, i) => {
    if (counter > i) return;
    const week = [];
    // get the first week start and count 7 days on from each (i + 7)
    for (let j = i; j < i + 7; j++) {
      week.push(excelData[j]);
      counter += 1;
    }
    weekList.push(week);
  });
  return weekList;
};

export const colsToData = (cols, data) => {
  const objArr = [];
  data.forEach((d) => {
    // each data, pull out the data for each col
    let obj = {};
    cols.forEach((c) => {
      let objValue = d[c.field];
      if (c.isDate) {
        objValue = formatDateForExcel(d[c.field]);
      }
      // ensure the field headers are labelled correctly in excel export
      const fieldName = c.fieldLabel ? c.fieldLabel : c.field;
      obj = { ...obj, [fieldName]: objValue };
    });
    objArr.push(obj);
  });
  return objArr;
};

export const jsonDataForExcelSheet = async (
  inputData,
  inputFields,
  dayField,
  dateField
) => {
  // create a json structure of the data
  // get a full list of fields
  let fields = [
    { label: "Day ", field: dayField },
    { label: "Date ", field: dateField },
  ];
  inputFields.forEach((f) => {
    const obj = { label: f["roomFieldLabel"], field: f["roomFieldDataField"] };
    fields = [...fields, obj];
  });

  const excelData = [];
  // now iterate over data and extract required fields
  inputData.forEach((i) => {
    // get the data from the iterate
    const obj = {};
    fields.forEach((f) => {
      if (f.label === "Date ") {
        obj[f.label] = formatDateFriendly(i[f.field]);
        return;
      }
      obj[f.label] = i[f.field];
    });
    excelData.push(obj);
  });
  //const jsonData = fields;
  return excelData;
};

// https://stackoverflow.com/questions/6298566/match-exact-string
// https://codepen.io/atchett/pen/RwMQVGv
// data = array of objects e.g. [{...},{...}]
// findString = string of data
// return object
export const findStringInData = (findString, data) => {
  const foundObj = data.filter((obj) =>
    Object.values(obj).some((val) => val.toString().includes(findString))
  );
  // const regEx = new RegExp(`^${findString}$`);
  // const foundObj = data.filter((obj) =>
  //   Object.values(obj).some((val) => val.toString().match(regEx))
  // );

  if (!foundObj) {
    return null;
  }
  return foundObj;
};
